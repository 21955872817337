import { createQueryKeys } from '@lukemorales/query-key-factory';
import type {
  SleekflowApisTicketingHubModelGetTicketActivitiesOutput,
  SleekflowApisTicketingHubModelGetTicketPrioritiesOutput,
  SleekflowApisTicketingHubModelGetTicketStatusesOutput,
  SleekflowApisTicketingHubModelGetTicketTypesOutput,
  SleekflowApisTicketingHubModelTicketCompanyConfig,
  TravisBackendTicketingHubDomainViewModelsGetSchemafulTicketsOutput,
} from '@sleekflow/sleekflow-core-typescript-rxjs-apis';
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import dayjs, { ConfigType } from 'dayjs';
import { useInjection } from 'inversify-react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { firstValueFrom } from 'rxjs';
import type { AjaxError } from 'rxjs/ajax';

import { API_ERROR_CODES } from '@/constants/apiErrorCodes';
import {
  TicketCountResult,
  TicketingService,
} from '@/services/ticketing/ticketing.service';
import { retryWithPredicate } from '@/utils/queryClient';

import type { TicketDetails } from './types';

export interface DownloadBlobUrlsArgs {
  blobNames: string[];
  blobType: string;
}

interface Filter {
  field_name: string;
  operator: string;
  value: string | number | null;
}

export interface TicketListParams {
  priority?: string[];
  status?: (string | Filter)[];
  assignee?: string[];
  channel?: {
    channel_type: string;
    channel_identity_id: string;
  }[];
  type?: string[];
  contact?: string[];
  due_date?: { filters: Filter[] }[];

  limit?: number;
  sort?: {
    field_name: string;
    direction: 'asc' | 'desc';
    is_case_sensitive?: boolean;
  };
  search?: string | null;
  deleted?: boolean;
  group_by?: string;
}

export const ticketingKeys = createQueryKeys('ticketing', {
  config: null,
  ticketTypes: null,
  ticketPriorities: null,
  ticketStatuses: null,
  uploadBlobs: null,
  getDownloadBlobUrls: ({ blobNames, blobType }: DownloadBlobUrlsArgs) => ({
    blobNames,
    blobType,
  }),
  list: (filters: TicketListParams) => [{ filters }],
  listTotalCount: (filters: TicketListParams) => [{ filters }],
  detail: (ticketId: string) => [ticketId],
  ticketActivities: (ticketId: string) => [ticketId],
});

export const useTicketingConfig = (
  options: UseQueryOptions<
    SleekflowApisTicketingHubModelTicketCompanyConfig | undefined
  > = {},
) => {
  const ticketingService = useInjection(TicketingService);

  return useQuery({
    queryKey: ticketingKeys.config,
    queryFn: () =>
      firstValueFrom(ticketingService.getCompanyTicketingConfig$()),
    staleTime: Infinity,
    useErrorBoundary: false, // Failing to get ticketing config should not break the app
    ...options,
  });
};

// Dynamic i18n keys:
// Ticket type:
// t('ticketing.type.question', 'Question')
// t('ticketing.type.incident', 'Incident')
// t('ticketing.type.problem', 'Problem')
// t('ticketing.type.task', 'Task')

// Ticket status:
// t('ticketing.status.to_do', 'To do')
// t('ticketing.status.in_progress', 'In Progress')
// t('ticketing.status.on_hold', 'On Hold')
// t('ticketing.status.reopened', 'Reopened')
// t('ticketing.status.resolved', 'Resolved')

// Ticket priority:
// t('ticketing.priority.urgent', 'Urgent')
// t('ticketing.priority.high', 'High')
// t('ticketing.priority.medium', 'Medium')
// t('ticketing.priority.low', 'Low')

export const useTicketingPriorities = ({
  enabled,
  ...options
}: UseQueryOptions<
  SleekflowApisTicketingHubModelGetTicketPrioritiesOutput | undefined,
  unknown,
  SleekflowApisTicketingHubModelGetTicketPrioritiesOutput['records']
> = {}) => {
  const { t } = useTranslation();
  const ticketingService = useInjection(TicketingService);
  const { data: ticketingConfig } = useTicketingConfig();

  return useQuery({
    queryKey: ticketingKeys.ticketPriorities,
    queryFn: () => firstValueFrom(ticketingService.getTicketPriorities$()),
    select: useCallback(
      (
        data:
          | SleekflowApisTicketingHubModelGetTicketPrioritiesOutput
          | undefined,
      ) =>
        data?.records?.map((priority) => ({
          ...priority,
          // eslint-disable-next-line react-i18n/no-dynamic-translation-keys
          label: t(priority.i18n_key || '', priority.label!),
        })),
      [t],
    ),
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled: ticketingConfig?.is_ticket_enabled && enabled,
    ...options,
  });
};

const isDefaultTicketType = (typeLabel: string | null | undefined) =>
  ['Question', 'Incident', 'Problem', 'Task'].includes(typeLabel || '');

export const useTicketingTypes = ({
  enabled,
  ...options
}: UseQueryOptions<
  SleekflowApisTicketingHubModelGetTicketTypesOutput | undefined,
  unknown,
  SleekflowApisTicketingHubModelGetTicketTypesOutput['records']
> = {}) => {
  const { t } = useTranslation();
  const ticketingService = useInjection(TicketingService);
  const { data: ticketingConfig } = useTicketingConfig();

  return useQuery({
    queryKey: ticketingKeys.ticketTypes,
    queryFn: () => firstValueFrom(ticketingService.getTicketTypes$()),
    select: useCallback(
      (data: SleekflowApisTicketingHubModelGetTicketTypesOutput | undefined) =>
        data?.records?.map((type) => ({
          ...type,
          // eslint-disable-next-line react-i18n/no-dynamic-translation-keys
          label: t(type.i18n_key || '', type.label!),
          // Hide i18n_key for custom ticket types
          i18n_key: isDefaultTicketType(type.label) ? type.i18n_key : undefined,
        })),
      [t],
    ),
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled: ticketingConfig?.is_ticket_enabled && enabled,
    useErrorBoundary: false, // ticket type is optional, no need to throw
    ...options,
  });
};

export const useTicketingStatuses = ({
  enabled,
  ...options
}: UseQueryOptions<
  SleekflowApisTicketingHubModelGetTicketStatusesOutput | undefined,
  unknown,
  SleekflowApisTicketingHubModelGetTicketStatusesOutput['records']
> = {}) => {
  const { t } = useTranslation();
  const ticketingService = useInjection(TicketingService);
  const { data: ticketingConfig } = useTicketingConfig();

  const { data, isLoading, refetch, isFetching } = useQuery({
    queryKey: ticketingKeys.ticketStatuses,
    queryFn: () => firstValueFrom(ticketingService.getTicketStatuses$()),
    select: useCallback(
      (
        data: SleekflowApisTicketingHubModelGetTicketStatusesOutput | undefined,
      ) =>
        data?.records?.map((status) => ({
          ...status,
          // eslint-disable-next-line react-i18n/no-dynamic-translation-keys
          label: t(status.i18n_key || '', status.label!),
        })),
      [t],
    ),
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled: ticketingConfig?.is_ticket_enabled && enabled,
    ...options,
  });

  const DefaultTicketStatus = useMemo(
    () =>
      (data
        ?.filter((status) => status.is_default && status.internal_value)
        .reduce(
          (acc, status) => ({
            ...acc,
            [(status.internal_value as string).toUpperCase()]: status.id!,
          }),
          {},
        ) ?? {}) as Record<string, string | undefined>,
    [data],
  );

  return {
    data,
    isLoading,
    DefaultTicketStatus,
    refetch,
    isFetching,
  };
};

export const buildDueDateFilter = (
  dueDateOptionValue: string | null,
  from: string | null,
  to: string | null,
  resolvedStatusId?: string | null,
) => {
  const toDayStart = (date: ConfigType) =>
    dayjs(date).set('hour', 0).set('minute', 0).set('second', 0).toISOString();

  const toDayEnd = (date: ConfigType) =>
    dayjs(date)
      .set('hour', 23)
      .set('minute', 59)
      .set('second', 59)
      .toISOString();

  const toFilters = (start: ConfigType, end?: ConfigType) => [
    {
      filters: [
        {
          field_name: 'due_date',
          operator: '>=',
          value: toDayStart(start),
        },
      ],
    },
    {
      filters: [
        {
          field_name: 'due_date',
          operator: '<=',
          value: toDayEnd(end ?? start),
        },
      ],
    },
  ];

  if (from && to) {
    return toFilters(from, to);
  }

  if (dueDateOptionValue === 'overdue') {
    return [
      {
        filters: [
          {
            field_name: 'due_date',
            operator: '<=',
            value: dayjs().toISOString(),
          },
        ],
      },
      {
        filters: [
          {
            field_name: 'status_id',
            operator: '!=',
            value: resolvedStatusId || '',
          },
        ],
      },
    ];
  }

  if (dueDateOptionValue === 'today') {
    const now = dayjs();
    return toFilters(now);
  }

  if (dueDateOptionValue === 'tomorrow') {
    const tomorrow = dayjs().add(1, 'day');
    return toFilters(tomorrow);
  }

  if (dueDateOptionValue === 'yesterday') {
    const yesterday = dayjs().subtract(1, 'day');
    return toFilters(yesterday);
  }

  if (dueDateOptionValue === 'next_7_days') {
    const now = dayjs();
    const next7Days = now.add(7, 'day');
    return toFilters(now, next7Days);
  }

  if (dueDateOptionValue === 'last_7_days') {
    const now = dayjs();
    const last7Days = now.subtract(7, 'day');
    return toFilters(last7Days, now);
  }

  if (dueDateOptionValue === 'null') {
    return [
      {
        filters: [
          {
            field_name: 'due_date',
            operator: '=',
            value: null,
          },
        ],
      },
    ];
  }
};

interface UseTicketListParams
  extends UseQueryOptions<
    | TravisBackendTicketingHubDomainViewModelsGetSchemafulTicketsOutput
    | undefined
  > {
  params?: TicketListParams;
}

const buildTicketListFilterGroup = (params: TicketListParams) => {
  const filterGroups = [
    {
      filters: [
        {
          field_name: 'record_statuses',
          operator: 'array_contains',
          value: (params.deleted ? 'Deleted' : 'Active') as string | number,
        },
      ],
    },
  ] as { filters: Filter[] }[];

  if (params.contact && params.contact.length > 0) {
    filterGroups.push({
      filters: params.contact.map((contactId) => ({
        field_name: 'sleekflow_user_profile_id',
        operator: '=',
        value: contactId,
      })),
    });
  }

  if (params.status && params.status.length > 0) {
    filterGroups.push({
      filters: params.status.map((statusIdOrFilter) =>
        typeof statusIdOrFilter === 'string'
          ? {
              field_name: 'status_id',
              operator: '=',
              value: statusIdOrFilter,
            }
          : statusIdOrFilter,
      ),
    });
  }

  if (params.due_date) {
    filterGroups.push(...params.due_date);
  }

  if (params.search) {
    filterGroups.push({
      filters: [
        {
          field_name: 'external_id',
          operator: '=',
          value: Number(params.search.replace('#', '')),
        },
        {
          field_name: 'title',
          operator: 'contains',
          value: params.search,
        },
      ],
    });
  }

  if (params.assignee && params.assignee.length > 0) {
    filterGroups.push({
      filters: params.assignee.map((assigneeId) => ({
        field_name: 'assignee_id',
        operator: '=',
        value: assigneeId,
      })),
    });
  }

  if (params.priority && params.priority.length > 0) {
    filterGroups.push({
      filters: params.priority.map((priorityId) => ({
        field_name: 'priority_id',
        operator: '=',
        value: priorityId,
      })),
    });
  }

  if (params.type && params.type.length > 0) {
    filterGroups.push({
      filters: params.type.map((typeId) => ({
        field_name: 'type_id',
        operator: '=',
        value: typeId,
      })),
    });
  }

  if (params.channel && params.channel.length > 0) {
    filterGroups.push(
      {
        filters: params.channel.map(({ channel_type }) => ({
          field_name: 'channel.channel_type',
          operator: '=',
          value: channel_type,
        })),
      },
      {
        filters: params.channel.map(({ channel_identity_id }) => ({
          field_name: 'channel.channel_identity_id',
          operator: '=',
          value: channel_identity_id,
        })),
      },
    );
  }

  return filterGroups;
};

export const useTicketList = ({
  enabled,
  params = {},
}: UseTicketListParams = {}) => {
  const ticketingService = useInjection(TicketingService);
  const { data: ticketingConfig } = useTicketingConfig();

  return useInfiniteQuery({
    queryKey: ticketingKeys.list(params),
    queryFn: ({ pageParam }) =>
      firstValueFrom(
        ticketingService.getTickets$({
          continuation_token: pageParam,
          filter_groups: buildTicketListFilterGroup(params),
          limit: params.limit,
          sort: params.sort || {
            direction: 'desc',
            field_name: 'created_at',
            is_case_sensitive: false,
          },
        }),
      ),
    staleTime: 0,
    keepPreviousData: true,
    getNextPageParam: (
      lastPage:
        | TravisBackendTicketingHubDomainViewModelsGetSchemafulTicketsOutput
        | undefined, // TODO: remove manual type on TypesScript v4.9+
    ) => lastPage?.continuation_token,
    enabled: ticketingConfig?.is_ticket_enabled && enabled,
  });
};

interface UseTickeCountParams<T>
  extends UseQueryOptions<TicketCountResult[], unknown, T> {
  params?: TicketListParams;
  select?: (data: TicketCountResult[]) => T;
}

export const useTicketCount = <T = TicketCountResult[]>({
  params = {},
  enabled,
  ...options
}: UseTickeCountParams<T> = {}) => {
  const ticketingService = useInjection(TicketingService);
  const { data: ticketingConfig } = useTicketingConfig();

  return useQuery({
    queryKey: ticketingKeys.listTotalCount(params),
    queryFn: () =>
      firstValueFrom(
        ticketingService.getTicketCount$({
          filter_groups: buildTicketListFilterGroup(params),
          group_bys: params.group_by ? [{ field_name: params.group_by }] : [],
        }),
      ),
    staleTime: 0,
    useErrorBoundary: false, // Ticket count is not essential, no need to throw
    enabled: ticketingConfig?.is_ticket_enabled && enabled,
    ...options,
  });
};

interface UseTicketOpions
  extends UseQueryOptions<TicketDetails | undefined, AjaxError> {
  ticketId?: string | null;
}

export const useTicket = ({
  ticketId,
  enabled,
  ...options
}: UseTicketOpions = {}) => {
  const ticketingService = useInjection(TicketingService);
  const { data: ticketingConfig } = useTicketingConfig();

  return useQuery({
    queryKey: ticketingKeys.detail(ticketId!),
    queryFn: () => firstValueFrom(ticketingService.getTicket$(ticketId!)),
    staleTime: 0,
    enabled: Boolean(ticketId) && ticketingConfig?.is_ticket_enabled && enabled,
    retry: retryWithPredicate(
      (error: AjaxError) =>
        ![API_ERROR_CODES.notFoundObjectException].includes(
          error.response.errorCode,
        ),
    ),
    ...options,
  });
};

export type TicketActivityPage =
  SleekflowApisTicketingHubModelGetTicketActivitiesOutput;

interface UseTicketActivitiesOptions
  extends UseInfiniteQueryOptions<TicketActivityPage | undefined> {
  ticketId?: string | null;
  limit?: number;
}

const TICKET_ACTIVITY_PAGE_SIZE = 1000;

export const useTicketActivities = (
  options: UseTicketActivitiesOptions = {},
) => {
  const ticketingService = useInjection(TicketingService);
  const { data: ticketingConfig } = useTicketingConfig();

  return useInfiniteQuery({
    queryKey: ticketingKeys.ticketActivities(options.ticketId!),
    queryFn: ({ pageParam }) =>
      firstValueFrom(
        ticketingService.getTicketActivities$({
          ticketId: options.ticketId!,
          continuationToken: pageParam,
          limit: options.limit ?? TICKET_ACTIVITY_PAGE_SIZE,
        }),
      ),
    enabled: Boolean(options.ticketId && ticketingConfig?.is_ticket_enabled),
    staleTime: 0,
    keepPreviousData: true,
    getNextPageParam: (lastPage: TicketActivityPage | undefined) =>
      lastPage?.continuation_token,
    ...options,
  });
};
