import { createQueryKeys } from '@lukemorales/query-key-factory';
import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { toApiFilterGroup } from '@/pages/CustomObjectData/shared/adapters';
import {
  CustomObjectDataFilterField,
  CustomObjectDataFilterFormOperator,
} from '@/pages/CustomObjectData/type';
import { toApiFilterCustomObjectListPayload } from '@/pages/Settings/SettingsCustomObject/adapters';

import { axiosClient, useAxios } from './axiosClient';

export const customObjectKeys = createQueryKeys('customObject', {
  getCustomObjectList: (params) => [params],
  getCustomObjectSchema: (schemaId: string) => [schemaId],
  getCustomObjectDataList: (params) => [params],
  getCustomObjectDataCount: (params) => [params],
  getCustomObjectsForConversation: (conversationId: string) => [conversationId],
  getCustomObjectDataUsage: null,
});

export type SchemaRelationshipType =
  | 'one-to-one'
  | 'one-to-many'
  | 'many-to-many';

export type CustomObjectSchema = {
  sleekflow_company_id: string;
  display_name: string;
  unique_name: string;
  relationship_type: SchemaRelationshipType;
  is_enabled: boolean;
  sorting_weight: number;
  primary_property: SchemaProperty & {
    primary_property_config: SchemaPrimaryPropertyConfig;
  };
  properties: Array<SchemaPropertyOrdered>;
  created_at: string;
  updated_at: string;
  id: string;
};

type SchemaPrimaryPropertyConfig = {
  is_auto_generated: boolean;
  sequential: {
    sequence_prefix: string;
    sequence_digit_length: number;
  };
};

export type SchemaProperty = {
  id: string;
  display_name: string;
  unique_name: string;
  data_type: { name: SchemaPropertyDataType };
  is_required: boolean;
  is_visible: boolean;
  is_pinned: boolean;
  is_searchable: boolean;
  created_by?: {
    sleekflow_staff_id: string;
    sleekflow_staff_team_ids: string[];
  };
  created_at: string;
  options?: SchemaPropertyDropdownOption[];
};

export type SchemaPropertyOrdered = SchemaProperty & {
  display_order: number;
  options: string[];
};

export type SchemaPropertyDropdownOption = {
  id: string;
  value: string | number | boolean;
  display_order: number;
};

export type SchemaPropertyDataType =
  | 'single_line_text'
  | 'numeric'
  | 'decimal'
  | 'single_choice'
  | 'multiple_choice'
  | 'boolean'
  | 'date'
  | 'datetime';

export type SchemaListSortingByOption =
  | 'display_name'
  | 'unique_name'
  | 'sorting_weight'
  | 'created_at'
  | 'updated_at'
  | 'is_enabled';

export type UpdatedCustomObject = {
  id: string;
  display_name: string;
  primary_property_display_name: string;
  is_enabled: boolean;
  properties: SchemaProperty[];
};

type GetCustomObjectListResponse = {
  schemas: CustomObjectSchema[];
  continuation_token: string | null;
  count: number;
};

export type CreateCustomObjectPayload = {
  display_name: string;
  unique_name: string;
  relationship_type: SchemaRelationshipType;
  primary_property_input: {
    display_name: string;
    unique_name: string;
    data_type: {
      name: string;
    };
    is_visible: boolean;
    is_pinned: boolean;
    is_searchable: boolean;
    primary_property_config: {
      is_auto_generated: boolean;
      sequential: {
        sequence_prefix: string;
        sequence_digit_length: number;
      } | null;
    };
  };
  property_inputs: {
    display_name: string;
    unique_name: string;
    data_type: {
      name: string;
    };
    is_required: boolean;
    is_visible: boolean;
    is_pinned: boolean;
    is_searchable: boolean;
    display_order: number;
    options?: {
      value: string;
      display_order: number;
    }[];
  }[];
};

type GetCustomObjectByUserProfileResponse = {
  schema: CustomObjectSchema;
  schemaful_objects: CustomObjectDataFromApi[];
  continuation_token: string;
};

export type FilterParam = {
  field: SchemaListSortingByOption;
  value: string | number | boolean;
  operator:
    | '='
    | '>'
    | '>='
    | '<='
    | '!='
    | 'arrayContains'
    | 'in'
    | 'startsWith';
};

type CreateCustomObjectDataPayload = {
  schema_id: string;
  property_values: CustomObjectDataPropertyValues;
  primary_property_value?: string;
  referenced_user_profile_id: string;
};

export type GetCustomObjectDataListResponse = {
  schemaful_objects: CustomObjectDataFromApi[];
  continuation_token: string;
  count: number;
};

export type CustomObjectDataFromApi = {
  id: string;
  schema_id: string;
  property_values: CustomObjectDataPropertyValues;
  primary_property_value: string;
  referenced_user_profile: ReferencedUserProfile;
  created_by?: {
    sleekflow_staff_id: string;
    sleekflow_staff_team_ids: string[];
  };
  updated_by?: {
    sleekflow_staff_id: string;
    sleekflow_staff_team_ids: string[];
  };
  created_at: string;
  updated_at: string;
};

type ReferencedUserProfile = {
  id: string;
  first_name: string;
  last_name: string;
};

export type CustomObjectDataPropertyValues = Record<
  string,
  string[] | number | string | boolean
>;

export type CustomObjectDataFilterApiOperator =
  | '='
  | '>'
  | '<'
  | '>='
  | '<='
  | '!='
  | 'arrayContains'
  | 'in'
  | 'startsWith'
  | 'contains'
  | 'isDefined';

export type FilterApi = {
  field_name: string;
  operator: string;
  value: boolean | null | string;
  is_property_value: boolean;
};

type GetCompanyCustomResponse = {
  custom_object_schemaful_object_num_per_company: number;
  custom_object_schema_num: number;
};

export const useCustomObjectListInfiniteQuery = <
  T = GetCustomObjectListResponse,
>({
  limit,
  select,
  suspense,
  filter,
  enabled,
}: {
  limit: number;
  select?: (data: InfiniteData<GetCustomObjectListResponse>) => InfiniteData<T>;
  suspense?: boolean;
  filter?: FilterParam;
  enabled?: boolean;
}) => {
  const axiosClient = useAxios();
  const getSchemaListUrl = '/CustomObject/Schemas/GetSchemas';

  return useInfiniteQuery(
    customObjectKeys.getCustomObjectList({ limit, filter }),
    async ({ signal, pageParam = null }) => {
      const payload = {
        limit,
        continuation_token: pageParam,
        sorts: [
          {
            field_name: 'sorting_weight',
            direction: 'asc',
          },
        ],
        filter_groups: filter
          ? [
              {
                filters: [
                  {
                    field_name: filter.field,
                    operator: filter.operator,
                    value: filter.value,
                  },
                ],
              },
            ]
          : [],
      };

      const result = await axiosClient.post<GetCustomObjectListResponse>(
        getSchemaListUrl,
        payload,
        {
          signal,
        },
      );
      return result.data;
    },
    {
      suspense,
      select,
      enabled,
      getNextPageParam: (lastPage) => {
        return lastPage.continuation_token;
      },
      keepPreviousData: true,
    },
  );
};

export const useReorderCustomObjectMutation = () => {
  const axiosClient = useAxios();
  const url = '/CustomObject/Schemas/RearrangeSchemaOrder';
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      activeId,
      priorToNewPositionId,
    }: {
      activeId: string;
      priorToNewPositionId: string;
    }) => {
      const response = await axiosClient.post(url, {
        id: activeId,
        prior_to_schema_id: priorToNewPositionId,
      });
      return response.data;
    },

    onSettled: () => {
      queryClient.invalidateQueries(customObjectKeys.getCustomObjectList._def);
    },
  });
};

export const useUpdateCustomObjectMutation = () => {
  const axiosClient = useAxios();
  const url = '/CustomObject/Schemas/UpdateSchema';
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (customObject: UpdatedCustomObject) => {
      const response = await axiosClient.post<UpdatedCustomObject>(
        url,
        customObject,
      );
      return response.data;
    },
    onSettled: (data) => {
      queryClient.invalidateQueries(customObjectKeys.getCustomObjectList._def);
      data &&
        queryClient.invalidateQueries(
          customObjectKeys.getCustomObjectSchema(data.id),
        );
      queryClient.invalidateQueries(
        customObjectKeys.getCustomObjectsForConversation._def,
      );
    },
  });
};

export const useDeleteCustomObjectMutation = () => {
  const axiosClient = useAxios();
  const url = '/CustomObject/Schemas/DeleteSchema';
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id: string) => {
      const response = await axiosClient.post(url, { id });
      return response.data;
    },
    onSettled: () => {
      queryClient.invalidateQueries(customObjectKeys.getCustomObjectList._def);
    },
  });
};

export const useCustomObjectSchemaQuery = <T = CustomObjectSchema>(
  schemaId: string,
  {
    select,
    suspense,
  }: { suspense?: boolean; select?: (data: CustomObjectSchema) => T } = {},
) => {
  const axiosClient = useAxios();
  const url = '/CustomObject/Schemas/GetSchema';
  return useQuery({
    queryKey: customObjectKeys.getCustomObjectSchema(schemaId),
    enabled: !!schemaId,
    queryFn: async () => {
      const response = await axiosClient.post(url, { id: schemaId });
      return response.data;
    },
    select,
    suspense,
  });
};

export const useDeleteCustomObjectPropertyMutation = (schemaId: string) => {
  const axiosClient = useAxios();
  const url = '/CustomObject/Schemas/DeleteProperty';
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      id,
      propertyId,
    }: {
      id: string;
      propertyId: string;
    }) => {
      const response = await axiosClient.post(url, {
        id,
        property_id: propertyId,
      });
      return response.data;
    },
    onSettled: () => {
      queryClient.invalidateQueries(
        customObjectKeys.getCustomObjectSchema(schemaId),
      );
    },
  });
};

export const useCreateCustomObjectMutation = () => {
  const axiosClient = useAxios();
  const url = '/CustomObject/Schemas/CreateSchema';
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: CreateCustomObjectPayload) => {
      const response = await axiosClient.post(url, data);
      return response.data;
    },
    onSettled: () => {
      queryClient.invalidateQueries(customObjectKeys.getCustomObjectList._def);
    },
  });
};

export const useFilterCustomObjectListMutation = () => {
  const axiosClient = useAxios();
  const url = '/CustomObject/Schemas/GetSchemas';
  return useMutation({
    mutationFn: async ({
      filtersWithAndLogic,
      filtersWithOrLogic,
      limit,
    }: {
      limit: number;
      filtersWithAndLogic?: Array<FilterParam>;
      filtersWithOrLogic?: Array<FilterParam>;
    }) => {
      const response = await axiosClient.post(
        url,
        toApiFilterCustomObjectListPayload({
          filtersWithAndLogic,
          filtersWithOrLogic,
          limit,
        }),
      );
      return response.data;
    },
  });
};

export function useConversationCustomObjectsQuery<
  T = GetCustomObjectByUserProfileResponse[],
>(
  conversationId: string | undefined,
  {
    select,
    suspense,
  }: {
    select?: (data: GetCustomObjectByUserProfileResponse[]) => T;
    suspense?: boolean;
  } = {},
) {
  return useQuery({
    queryKey: customObjectKeys.getCustomObjectsForConversation(
      conversationId ?? '',
    ),
    enabled: conversationId !== undefined,
    queryFn: async () => {
      const url = '/CustomObject/SchemafulObjects/getByUserProfile';
      const response = await axiosClient.post<
        any,
        AxiosResponse<GetCustomObjectByUserProfileResponse[]>
      >(url, {
        referenced_user_profile_id: conversationId,
        schemaful_object_limit: 1000,
        is_fetch_all_schemas: true,
      });
      return response.data;
    },
    select,
    suspense,
  });
}

export const useCreateCustomObjectDataMutation = () => {
  const url = '/CustomObject/SchemafulObjects/CreateSchemafulObject';
  const axiosClient = useAxios();

  return useMutation({
    mutationFn: async (payload: CreateCustomObjectDataPayload) => {
      const response = await axiosClient.post(url, payload);
      return response.data;
    },
  });
};

export const useCustomObjectDataListInfiniteQuery = <
  T = GetCustomObjectDataListResponse,
>({
  limit,
  select,
  customObjectId,
  sorts,
  suspense,
  search,
  filters,
  createDateRange,
  updateDateRange,
  createdBy,
}: {
  limit: number;
  customObjectId: string;
  select?: (
    data: InfiniteData<GetCustomObjectDataListResponse>,
  ) => InfiniteData<T>;
  sorts?: {
    field: string;
    direction: 'asc' | 'desc';
  };
  suspense?: boolean;
  search?: string;
  filters: Array<
    CustomObjectDataFilterField & {
      operator: CustomObjectDataFilterFormOperator;
    }
  > | null;
  createDateRange?: Date[];
  updateDateRange?: Date[];
  createdBy: string | null;
}) => {
  const axiosClient = useAxios();
  const url = '/CustomObject/SchemafulObjects/GetSchemafulObjects';
  return useInfiniteQuery(
    customObjectKeys.getCustomObjectDataList({
      limit,
      customObjectId,
      sorts,
      search,
      createDateRange,
      updateDateRange,
      createdBy,
      filters,
    }),
    async ({ signal, pageParam = null }) => {
      const payload = {
        limit,
        continuation_token: pageParam,
        schema_id: customObjectId,
        filter_groups: toApiFilterGroup({
          filters: filters,
          search,
          createDateRange,
          updateDateRange,
          createdBy,
        }),
        sorts: sorts
          ? [
              {
                field_name: sorts?.field,
                direction: sorts?.direction,
                is_property_value: true,
              },
            ]
          : [],
      };

      const result = await axiosClient.post<GetCustomObjectDataListResponse>(
        url,
        payload,
        {
          signal,
        },
      );
      return result.data;
    },
    {
      suspense,
      select,
      getNextPageParam: (lastPage) => {
        return lastPage.continuation_token;
      },
      keepPreviousData: true,
    },
  );
};

export const useDeleteCustomObjectDataMutation = () => {
  const axiosClient = useAxios();
  const url = '/CustomObject/SchemafulObjects/DeleteSchemafulObjects';
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      customObjectId,
      ids,
    }: {
      customObjectId: string;
      ids: string[];
    }) => {
      const response = await axiosClient.post(url, {
        schema_id: customObjectId,
        ids,
      });
      return response.data;
    },
    onSettled: () => {
      queryClient.invalidateQueries(
        customObjectKeys.getCustomObjectDataList._def,
      );
      queryClient.invalidateQueries(
        customObjectKeys.getCustomObjectDataCount._def,
      );
    },
  });
};

export const useUpdateCustomObjectDataMutation = () => {
  const axiosClient = useAxios();
  const url = '/CustomObject/SchemafulObjects/UpdateSchemafulObject';
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (payload: CreateCustomObjectDataPayload) => {
      const response = await axiosClient.post(url, payload);
      return response.data;
    },
    onSettled: () => {
      queryClient.invalidateQueries(
        customObjectKeys.getCustomObjectDataList._def,
      );
    },
  });
};

export const useGetCustomObjectDataCountQuery = ({
  customObjectId,
  suspense,
  search,
  filters,
  createDateRange,
  updateDateRange,
  enabled,
  createdBy,
}: {
  customObjectId: string;
  suspense?: boolean;
  search?: string;
  filters: Array<
    CustomObjectDataFilterField & {
      operator: CustomObjectDataFilterFormOperator;
    }
  > | null;
  createDateRange?: Date[];
  updateDateRange?: Date[];
  createdBy?: string | null;
  enabled?: boolean;
}) => {
  const axiosClient = useAxios();
  const url = '/CustomObject/SchemafulObjects/GetSchemafulObjectsCount';
  return useQuery({
    queryKey: customObjectKeys.getCustomObjectDataCount({
      customObjectId,
      search,
      filters,
      createDateRange,
      updateDateRange,
      createdBy,
    }),
    queryFn: async () => {
      const payload = {
        schema_id: customObjectId,
        filter_groups: toApiFilterGroup({
          filters: filters,
          search,
          createDateRange,
          updateDateRange,
          createdBy,
        }),
      };

      const response = await axiosClient.post(url, payload);
      return response.data;
    },
    suspense,
    enabled,
  });
};

export const useGetCompanyCustomObjectDataUsage = <
  T = GetCompanyCustomResponse,
>({
  select,
}: {
  select?: (data: GetCompanyCustomResponse) => T;
} = {}) => {
  const axiosClient = useAxios();
  const url = '/CustomObject/GetUsageStatistics';
  return useQuery({
    queryKey: customObjectKeys.getCustomObjectDataUsage,
    queryFn: async () => {
      const response = await axiosClient.post<GetCompanyCustomResponse>(url);
      return response.data;
    },
    select,
  });
};
