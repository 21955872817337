import { TravisBackendConversationDomainViewModelsConversationNoCompanyResponseViewModel } from '@sleekflow/sleekflow-core-typescript-rxjs-apis';
import { inject, injectable } from 'inversify';
import { combineLatest, map, of, switchMap, take } from 'rxjs';

import { ConversationMessageService } from '@/services/conversation-messages/conversation-message.service';
import { ConversationMessageWrapper } from '@/services/conversation-messages/managers/conversation-message-wrapper';
import { GetConversationsFilter } from '@/services/conversations/models/get-conversations-filter';
import { UserService } from '@/services/user.service';

import { ConversationWrapper } from './managers/conversation-wrapper';
import { ConversationWrapperUpdate } from './managers/conversation-wrapper-manager.service';

@injectable()
export class ConversationMatcherService {
  constructor(
    @inject(UserService) private userService: UserService,
    @inject(ConversationMessageService)
    private conversationMessageService: ConversationMessageService,
  ) {}

  public matchConversationWrapper(
    getConversationsFilter: GetConversationsFilter,
    cw: ConversationWrapper,
  ) {
    return combineLatest({
      status: cw.getStatus$(),
      assignee: cw.getAssignee$(),
      labels: cw.getLabels$(),
      assignedTeam: cw.getAssignedTeam$(),
      unreadMessageCount: cw.getUnreadMessageCount$(),
      collaborators: cw.getCollaborators$(),
      myStaff: this.userService.getMyStaff$(),
      lastMessage: cw.getLastMessageId$().pipe(
        take(1),
        switchMap((lastMessageId) => {
          if (lastMessageId === null) {
            return of(null);
          }

          return this.conversationMessageService
            .getMessage$(cw.getId(), lastMessageId)
            .pipe(take(1));
        }),
      ),
    }).pipe(
      take(1),
      map((x) => {
        if (getConversationsFilter.isStaffAssigned === true) {
          if (x.assignee === null) {
            return false;
          }
        }
        if (getConversationsFilter.isStaffAssigned === false) {
          if (x.assignee !== null) {
            return false;
          }
        }

        if (getConversationsFilter.assignedStaffId !== undefined) {
          if (x.assignee?.id !== getConversationsFilter.assignedStaffId) {
            return false;
          }
        }

        if (
          getConversationsFilter.assignedTeamId &&
          getConversationsFilter.assignedTeamId !== x.assignedTeam?.id
        ) {
          return false;
        }

        if (!['all', undefined].includes(getConversationsFilter.status)) {
          if (x.status !== getConversationsFilter.status) {
            return false;
          }
        }

        if (getConversationsFilter.isUnread !== undefined) {
          if (getConversationsFilter.isUnread && x.unreadMessageCount === 0) {
            return false;
          }
        }

        if (getConversationsFilter.labelIds !== undefined) {
          if (getConversationsFilter.labelIds.length > 0) {
            if (x.labels?.length === 0) {
              return false;
            }

            if (
              x.labels?.every(
                (label) => !getConversationsFilter.labelIds?.includes(label.id),
              )
            ) {
              return false;
            }
          }
        }

        if (getConversationsFilter.isCollaborated !== undefined) {
          if (
            getConversationsFilter.isCollaborated &&
            x.collaborators.every((c) => c.staffId != x.myStaff.staffId)
          ) {
            return false;
          }
        }

        if (getConversationsFilter.isMentioned !== undefined) {
          if (
            getConversationsFilter.isMentioned &&
            (x.lastMessage === null ||
              x.lastMessage?.getMessageAssignee()?.staffId !==
                x.myStaff.staffId)
          ) {
            return false;
          }
        }

        if (
          getConversationsFilter.channelIds !== undefined &&
          getConversationsFilter.channelIds.length > 0
        ) {
          if (
            getConversationsFilter.channelIds.every(
              (channelId) =>
                channelId !== x.lastMessage?.getChannelIdentityId(),
            )
          ) {
            return false;
          }
        }

        // TODO
        // isTeamUnassigned?: boolean;

        return true;
      }),
    );
  }

  public matchConversationWrapperUpdate(
    getConversationsFilter: GetConversationsFilter,
    cwu: ConversationWrapperUpdate,
    myStaffId: number,
  ) {
    let areAllFiltersMatched = true;

    if (cwu.type === 'assignee' && cwu.newValue.assignee !== undefined) {
      if (getConversationsFilter.assignedStaffId) {
        // New Value is Unassigned
        if (cwu.newValue.assignee === null) {
          areAllFiltersMatched = false;
        }
        // New Value does not match filter
        else if (
          cwu.newValue.assignee.id !== getConversationsFilter.assignedStaffId
        ) {
          areAllFiltersMatched = false;
        }
      }

      if (getConversationsFilter.isStaffAssigned === true) {
        if (cwu.newValue.assignee === null) {
          areAllFiltersMatched = false;
        }
      }
      if (getConversationsFilter.isStaffAssigned === false) {
        if (cwu.newValue.assignee !== null) {
          areAllFiltersMatched = false;
        }
      }
    } else if (
      cwu.type === 'status' &&
      getConversationsFilter.status &&
      getConversationsFilter.status !== 'all' &&
      cwu.newValue.status !== undefined
    ) {
      if (cwu.newValue.status !== getConversationsFilter.status) {
        areAllFiltersMatched = false;
      }
    } else if (
      cwu.type === 'unreadMessageCount' &&
      getConversationsFilter.isUnread &&
      cwu.newValue.unreadMessageCount !== undefined
    ) {
      if (cwu.newValue.unreadMessageCount === 0) {
        areAllFiltersMatched = false;
      }
    } else if (
      cwu.type === 'labels' &&
      getConversationsFilter.labelIds &&
      cwu.newValue.labels !== undefined
    ) {
      if (cwu.newValue.labels.length === 0) {
        areAllFiltersMatched = false;
      } else if (
        cwu.newValue.labels.every(
          (label) => !getConversationsFilter.labelIds?.includes(label.id),
        )
      ) {
        areAllFiltersMatched = false;
      }
    } else if (
      cwu.type === 'collaborators' &&
      getConversationsFilter.isCollaborated &&
      cwu.newValue.collaborators !== undefined
    ) {
      if (cwu.newValue.collaborators.every((c) => c.staffId != myStaffId)) {
        areAllFiltersMatched = false;
      }
    } else if (cwu.type === 'lastMessage') {
      if (getConversationsFilter.isMentioned) {
        if (
          cwu.newValue.lastMessage === undefined ||
          cwu.newValue.lastMessage === null ||
          cwu.newValue.lastMessage.getMessageAssignee()?.staffId !== myStaffId
        ) {
          areAllFiltersMatched = false;
        }
      }
      if (getConversationsFilter.channelIds) {
        if (
          cwu.newValue.lastMessage === undefined ||
          cwu.newValue.lastMessage === null ||
          getConversationsFilter.channelIds.every(
            (channelId) =>
              channelId !== cwu.newValue.lastMessage?.getChannelIdentityId(),
          )
        ) {
          areAllFiltersMatched = false;
        }
      }
    } else if (cwu.type === 'assignedTeam') {
      if (
        getConversationsFilter.assignedTeamId &&
        getConversationsFilter.assignedTeamId !== cwu.newValue.assignedTeam?.id
      )
        areAllFiltersMatched = false;
    }

    // TODO
    // isTeamUnassigned?: boolean;

    return areAllFiltersMatched;
  }

  public matchConversation(
    getConversationsFilter: GetConversationsFilter,
    travisBackendConversationDomainViewModelsConversationNoCompanyResponseViewModel: TravisBackendConversationDomainViewModelsConversationNoCompanyResponseViewModel,
    myStaffId: number,
    lastMessage: ConversationMessageWrapper | null | undefined,
  ) {
    if (getConversationsFilter.isStaffAssigned === true) {
      if (
        travisBackendConversationDomainViewModelsConversationNoCompanyResponseViewModel.assignee ===
        null
      ) {
        return false;
      }
    }
    if (getConversationsFilter.isStaffAssigned === false) {
      if (
        travisBackendConversationDomainViewModelsConversationNoCompanyResponseViewModel.assignee !==
        null
      ) {
        return false;
      }
    }

    if (getConversationsFilter.assignedStaffId !== undefined) {
      if (
        travisBackendConversationDomainViewModelsConversationNoCompanyResponseViewModel
          .assignee?.userInfo?.id !== getConversationsFilter.assignedStaffId
      ) {
        return false;
      }
    }

    if (getConversationsFilter.assignedTeamId) {
      if (
        travisBackendConversationDomainViewModelsConversationNoCompanyResponseViewModel
          .assignedTeam?.id !== getConversationsFilter.assignedTeamId
      ) {
        return false;
      }
    }

    if (
      getConversationsFilter.status !== undefined &&
      getConversationsFilter.status !== 'all'
    ) {
      if (
        travisBackendConversationDomainViewModelsConversationNoCompanyResponseViewModel.status !==
        getConversationsFilter.status
      ) {
        return false;
      }
    }

    if (getConversationsFilter.isUnread !== undefined) {
      if (
        getConversationsFilter.isUnread &&
        travisBackendConversationDomainViewModelsConversationNoCompanyResponseViewModel.unreadMessageCount ===
          0
      ) {
        return false;
      }
    }

    if (getConversationsFilter.labelIds !== undefined) {
      if (getConversationsFilter.labelIds.length > 0) {
        if (
          travisBackendConversationDomainViewModelsConversationNoCompanyResponseViewModel
            .conversationHashtags?.length === 0
        ) {
          return false;
        }

        if (
          travisBackendConversationDomainViewModelsConversationNoCompanyResponseViewModel.conversationHashtags?.every(
            (label) => !getConversationsFilter.labelIds?.includes(label.id!),
          )
        ) {
          return false;
        }
      }
    }

    if (getConversationsFilter.isCollaborated !== undefined) {
      if (
        getConversationsFilter.isCollaborated &&
        travisBackendConversationDomainViewModelsConversationNoCompanyResponseViewModel.additionalAssignees?.every(
          (c) => c.assignee != null && c.assignee.staffId !== myStaffId,
        )
      ) {
        return false;
      }
    }

    if (getConversationsFilter.isMentioned !== undefined) {
      if (
        getConversationsFilter.isMentioned &&
        (lastMessage === null ||
          lastMessage === undefined ||
          lastMessage.getMessageAssignee()?.staffId !== myStaffId)
      ) {
        return false;
      }
    }

    if (
      getConversationsFilter.channelIds !== undefined &&
      getConversationsFilter.channelIds.length > 0
    ) {
      if (
        getConversationsFilter.channelIds.every(
          (channelId) => channelId !== lastMessage?.getChannelIdentityId(),
        )
      ) {
        return false;
      }
    }

    // TODO
    // isTeamUnassigned?: boolean;

    return true;
  }
}
