import { TFunction } from 'react-i18next';

import { CustomObjectPropertyType } from '@/pages/Settings/SettingsCustomObject/type';

type RuleOption = {
  displayName: string;
  value: string;
};

export type RuleOptions = {
  contains: RuleOption;
  notContains: RuleOption;
  isNotEmpty: RuleOption;
  isEmpty: RuleOption;
  notContainsExactly: RuleOption;
  containsExactly: RuleOption;
  equalsTo: RuleOption;
  isAfter: RuleOption;
  isBefore: RuleOption;
  isLessThan: RuleOption;
  isGreaterThan: RuleOption;
};

export const FILTER_OPERATORS = [
  'contains',
  'notContains',
  'isNotEmpty',
  'isEmpty',
  'notContainsExactly',
  'containsExactly',
  'containsAnyOf',
  'containsAllOf',
  '=',
  '>',
  '<',
] as const;

export const getRuleOptions = (t: TFunction) =>
  ({
    contains: {
      displayName: t('custom-object-data.filter-rules.contains', {
        defaultValue: 'Contains',
      }),
      value: 'contains',
    },
    notContains: {
      displayName: t('custom-object-data.filter-rules.not-contains', {
        defaultValue: 'Does not contain',
      }),
      value: 'notContains',
    },
    isNotEmpty: {
      displayName: t('custom-object-data.filter-rules.is-not-empty', {
        defaultValue: 'Is not empty',
      }),
      value: 'isNotEmpty',
    },
    isEmpty: {
      displayName: t('custom-object-data.filter-rules.is-empty', {
        defaultValue: 'Is empty',
      }),
      value: 'isEmpty',
    },
    notContainsExactly: {
      displayName: t('custom-object-data.filter-rules.is-not-contain-exactly', {
        defaultValue: 'Does not contain exactly',
      }),
      value: 'notContainsExactly',
    },
    containsAnyOf: {
      displayName: t('custom-object-data.filter-rules.contains-any-of', {
        defaultValue: 'Contains any of',
      }),
      value: 'containsAnyOf',
    },
    containsAllOf: {
      displayName: t('custom-object-data.filter-rules.contains-all-of', {
        defaultValue: 'Contains all of',
      }),
      value: 'containsAllOf',
    },
    containsExactly: {
      displayName: t('custom-object-data.filter-rules.contains-exactly', {
        defaultValue: 'Contains exactly',
      }),
      value: 'containsExactly',
    },
    equalsTo: {
      displayName: t('custom-object-data.filter-rules.equals-to', {
        defaultValue: 'Equals to',
      }),
      value: '=',
    },

    isAfter: {
      displayName: t('custom-object-data.filter-rules.is-after', {
        defaultValue: 'Is after',
      }),
      value: '>',
    },
    isBefore: {
      displayName: t('custom-object-data.filter-rules.is-before', {
        defaultValue: 'Is before',
      }),
      value: '<',
    },
    isLessThan: {
      displayName: t('custom-object-data.filter-rules.is-less', {
        defaultValue: 'Is less than',
      }),
      value: '<',
    },
    isGreaterThan: {
      displayName: t('custom-object-data.filter-rules.is-greater', {
        defaultValue: 'Is greater than',
      }),
      value: '>',
    },
  } as const);

export const getFilterRuleOptionConfig = (
  t: TFunction,
  type: CustomObjectPropertyType,
) => {
  const RULE_OPTIONS = getRuleOptions(t);

  switch (type) {
    case 'date':
    case 'dateTime':
      return [
        RULE_OPTIONS.equalsTo,
        RULE_OPTIONS.isBefore,
        RULE_OPTIONS.isAfter,
        RULE_OPTIONS.isEmpty,
        RULE_OPTIONS.isNotEmpty,
      ];
    case 'singleSelection':
    case 'boolean':
      return [
        RULE_OPTIONS.isEmpty,
        RULE_OPTIONS.isNotEmpty,
        RULE_OPTIONS.notContainsExactly,
        RULE_OPTIONS.containsExactly,
      ];
    case 'multiSelection':
      return [
        RULE_OPTIONS.isEmpty,
        RULE_OPTIONS.isNotEmpty,
        RULE_OPTIONS.containsAllOf,
        RULE_OPTIONS.containsAnyOf,
      ];
    case 'text':
      return [
        RULE_OPTIONS.contains,
        RULE_OPTIONS.notContains,
        RULE_OPTIONS.isNotEmpty,
        RULE_OPTIONS.isEmpty,
      ];
    case 'number':
    case 'decimalNumber':
      return [
        RULE_OPTIONS.equalsTo,
        RULE_OPTIONS.isLessThan,
        RULE_OPTIONS.isGreaterThan,
        RULE_OPTIONS.isEmpty,
        RULE_OPTIONS.isNotEmpty,
      ];
    default:
      return [];
  }
};
