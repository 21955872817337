import dayjs from 'dayjs';

import { FilterApi } from '@/api/customObject';
import { tryParseEncodedURL } from '@/utils/url';

import {
  CustomObjectDataFilterField,
  CustomObjectDataFilterFormOperator,
} from '../type';
import { validateFilterFromUrlSchema } from './validationSchemas';

export const getParsedFiltersFromUrl = (
  data: string | null,
  propertyIds: string[],
):
  | (CustomObjectDataFilterField & {
      operator: CustomObjectDataFilterFormOperator;
    })[]
  | null => {
  try {
    const parsedData = tryParseEncodedURL(data);
    const validationSchema = validateFilterFromUrlSchema(propertyIds);
    const validatedData = validationSchema.validateSync(parsedData) || null;
    return validatedData ?? null;
  } catch (_e) {
    return null;
  }
};

export const EMPTY_FILTER: CustomObjectDataFilterField = {
  value: '',
  operator: '',
  field: '',
};

/*                           To api filter params helpers                        */

export function getFilterDateRange(
  startDate: string | null,
  endDate: string | null,
) {
  if (!startDate || !endDate) return undefined;
  return [new Date(startDate), new Date(endDate)];
}

export const getIsEmptyFilter = (
  filter: CustomObjectDataFilterField,
): {
  filters: FilterApi[];
} => {
  return {
    filters: [
      {
        field_name: filter.field,
        operator: 'isDefined',
        value: false,
        is_property_value: true,
      },
      {
        field_name: filter.field,
        operator: '=',
        value: null,
        is_property_value: true,
      },
      {
        field_name: filter.field,
        operator: '=',
        value: '',
        is_property_value: true,
      },
    ],
  };
};

export const getIsNotEmptyFilter = (filter: CustomObjectDataFilterField) => {
  return [
    {
      filters: [
        {
          field_name: filter.field,
          operator: '!=',
          value: null,
          is_property_value: true,
        },
      ],
    },
    {
      filters: [
        {
          field_name: filter.field,
          operator: 'isDefined',
          value: true,
          is_property_value: true,
        },
      ],
    },
    {
      filters: [
        {
          field_name: filter.field,
          operator: '!=',
          value: '',
          is_property_value: true,
        },
      ],
    },
  ];
};

export const getDateRangeFilter = (
  dateRange: Date[],
  fieldName: 'updated_at' | 'created_at',
) => {
  const from = dayjs(dateRange[0]);
  const to = dayjs(dateRange[1]).add(1, 'day');

  return [
    {
      filters: [
        {
          field_name: fieldName,
          operator: '>=',
          value: from.toISOString(),
          is_property_value: false,
        },
      ],
    },
    {
      filters: [
        {
          field_name: fieldName,
          operator: '<=',
          value: to.toISOString(),
          is_property_value: false,
        },
      ],
    },
  ];
};
