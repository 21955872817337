import { interfaces } from 'inversify';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  forkJoin,
  map,
  Observable,
  startWith,
  switchMap,
  take,
  takeUntil,
} from 'rxjs';

import { RxjsUtils } from '@/services/rxjs-utils/rxjs-utils';

import { ArrayPagedDataSource } from '../data-sources/array-paged-data-source';
import { DisposableDataSource } from '../data-sources/disposable-data-source';
import { DataSourceListRange } from '../data-sources/models/data-source-list-range';
import { Wrapper } from '../models/wrapper';
import { UserProfileWrapper } from '../user-profiles/managers/user-profile-wrapper';
import { UserProfileService } from '../user-profiles/user-profile.service';
import { UserService } from '../user.service';
import { ConversationMatcherService } from './conversation-matcher.service';
import { ConversationService } from './conversation.service';
import { ConversationWrapper } from './managers/conversation-wrapper';
import { SearchConversationsParams } from './models/search-conversations-params';

export class SearchConversationWithUserProfileResult implements Wrapper {
  constructor(
    public userProfile: UserProfileWrapper,
    public conversation: ConversationWrapper,
  ) {}

  getId(): string | number {
    return this.userProfile.getId();
  }

  destroy(): void {
    // This is intentionally left blank as userProfile and conversation are managed by their own managers
  }

  subscribe(disposableDataSource: DisposableDataSource): void {
    this.userProfile.subscribe(disposableDataSource);
    this.conversation.subscribe(disposableDataSource);
  }

  unsubscribe(disposableDataSource: DisposableDataSource): void {
    this.userProfile.unsubscribe(disposableDataSource);
    this.conversation.unsubscribe(disposableDataSource);
  }

  observed() {
    return this.userProfile.observed() || this.conversation.observed();
  }
}

export class SearchConversationWithUserProfileDataSource extends ArrayPagedDataSource<SearchConversationWithUserProfileResult> {
  private readonly conversationService: ConversationService;
  private readonly conversationMatcherService: ConversationMatcherService;
  private readonly userProfileService: UserProfileService;
  private readonly userService: UserService;

  private readonly pageSize = 20;
  private readonly fetchedPageIdxs = new Set<number>();

  private readonly totalNumberOfItems$$ = new BehaviorSubject(1);

  private hasSetup = false;

  public constructor(container: interfaces.Container) {
    super();

    this.conversationService =
      container.get<ConversationService>(ConversationService);
    this.conversationMatcherService = container.get<ConversationMatcherService>(
      ConversationMatcherService,
    );
    this.userProfileService =
      container.get<UserProfileService>(UserProfileService);
    this.userService = container.get<UserService>(UserService);
  }

  public getTotalNumberOfItems$(): Observable<number> {
    return this.totalNumberOfItems$$.pipe(takeUntil(this.getDisconnect$()));
  }

  public setupAndGet$(
    searchConversationsParams: SearchConversationsParams,
    listRange$: Observable<DataSourceListRange>,
  ): Observable<SearchConversationWithUserProfileResult[]> {
    listRange$
      .pipe(
        distinctUntilChanged((a, b) => {
          return a.start == b.start && a.end == b.end;
        }),
        takeUntil(this.getComplete$()),
        takeUntil(this.getDisconnect$()),
      )
      .subscribe((range) => {
        const endPage = this.getPageForIndex(range.end);
        this.fetchPage(endPage + 1, searchConversationsParams);
      });

    if (this.hasSetup) {
      return this.getCachedItems$();
    }

    this.hasSetup = true;

    // Yields the initial empty array
    this.yieldSortedItems(true);

    this.setup(searchConversationsParams);

    return this.getCachedItems$();
  }

  private setup(searchConversationsParams: SearchConversationsParams): void {
    this.setupSortFunc(this.sortDescFunc);

    this.fetchPage(0, searchConversationsParams);

    this.handleUpdateConversations(searchConversationsParams);
  }

  private getPageForIndex(index: number): number {
    return Math.floor(index / this.pageSize);
  }

  private fetchPage(
    page: number,
    searchConversationsParams: SearchConversationsParams,
  ): void {
    if (this.fetchedPageIdxs.has(page)) {
      return;
    }
    this.fetchedPageIdxs.add(page);

    const observable$ =
      this.conversationService.searchUserProfilesWithConversations$(
        page * this.pageSize,
        this.pageSize,
        searchConversationsParams,
        searchConversationsParams.searchKeyword,
      );

    // Update isLoading to true before starting to fetch data
    this.setIsFetchingNextPage(true);

    observable$
      .pipe(
        takeUntil(this.getComplete$()),
        takeUntil(this.getDisconnect$()),
        RxjsUtils.getRetryAPIRequest(),
      )
      .subscribe({
        next: (tuple) => {
          const totalNumberOfUserProfiles = tuple.totalNumberOfUserProfiles;
          const searchResults = tuple.searchResults;

          this.setTotalNumberOfItems(totalNumberOfUserProfiles);

          if (searchResults && searchResults.length > 0) {
            if (searchResults.length < this.pageSize) {
              this.complete();
            }

            this.addItems(
              searchResults.map((sr) => {
                return new SearchConversationWithUserProfileResult(
                  sr.userProfile,
                  sr.conversation,
                );
              }),
            );
          } else {
            this.yieldSortedItems();
          }
        },
        error: (error) => {
          console.error(error);
        },
        complete: () => {
          this.setIsFetchingNextPage(false);
        },
      });
  }

  private sortDescFunc = (
    a: SearchConversationWithUserProfileResult,
    b: SearchConversationWithUserProfileResult,
  ) => {
    return -a.conversation
      .getUpdatedTime()
      .localeCompare(b.conversation.getUpdatedTime());
  };

  private setTotalNumberOfItems(total: number | ((prev: number) => number)) {
    const currentValue = this.totalNumberOfItems$$.value;
    const newValue = typeof total === 'number' ? total : total(currentValue);
    if (currentValue !== newValue) {
      this.totalNumberOfItems$$.next(newValue);
    }
  }

  private handleUpdateConversations({
    searchKeyword,
    isStaffAssigned: _,
    ...getConversationsFilter
  }: SearchConversationsParams) {
    const lowerCaseKeyword = searchKeyword.toLowerCase();

    this.conversationService
      .getCachedConversationUpdate$()
      .pipe(
        filter(([, cwu]) =>
          ['status', 'lastMessage', 'updatedTime'].includes(cwu.type),
        ),
        switchMap(([conversation, cwu]) =>
          forkJoin([
            this.userService.getMyStaff$(),
            this.userProfileService.getUserProfileWrapper$(
              conversation.getUserProfileId(),
            ),
          ]).pipe(
            map(([myStaff, userProfile]) => ({
              conversation,
              conversationWrapperUpdate: cwu,
              myStaff,
              userProfile,
            })),
          ),
        ),
        switchMap(({ userProfile, ...rest }) =>
          combineLatest([
            userProfile.getFirstName$().pipe(startWith('')),
            userProfile.getLastName$().pipe(startWith('')),
            userProfile.getFullName$().pipe(startWith('')),
            userProfile.getPhoneNumber$().pipe(startWith('')),
          ]).pipe(
            take(1),
            map(([firstName, lastName, fullName, phoneNumber]) => ({
              userProfile,
              firstName,
              lastName,
              fullName,
              phoneNumber,
              ...rest,
            })),
          ),
        ),
        filter(
          ({ firstName, lastName, fullName, phoneNumber }) =>
            firstName.toLowerCase().includes(lowerCaseKeyword) ||
            lastName.toLowerCase().includes(lowerCaseKeyword) ||
            fullName.toLowerCase().includes(lowerCaseKeyword) ||
            phoneNumber.toLowerCase().includes(lowerCaseKeyword),
        ),
        takeUntil(this.getDisconnect$()),
      )
      .subscribe(
        ({ conversation, conversationWrapperUpdate, myStaff, userProfile }) => {
          if (
            ['lastMessage', 'updatedTime'].includes(
              conversationWrapperUpdate.type,
            )
          ) {
            this.yieldSortedItems();
            return;
          }

          const isMatchedConversationWrapperUpdate =
            this.conversationMatcherService.matchConversationWrapperUpdate(
              getConversationsFilter,
              conversationWrapperUpdate,
              myStaff.staffId,
            );

          if (!isMatchedConversationWrapperUpdate) {
            const prevLength = this.cachedItems.length;
            this.removeItemById(conversation.getUserProfileId());
            const delta = prevLength - this.cachedItems.length;

            this.setTotalNumberOfItems((prev) => prev - delta);
            return;
          }

          if (
            ['status', 'assignedTeam'].includes(conversationWrapperUpdate.type)
          ) {
            const prevLength = this.cachedItems.length;
            this.addItem(
              new SearchConversationWithUserProfileResult(
                userProfile,
                conversation,
              ),
            );
            const delta = this.cachedItems.length - prevLength;

            this.setTotalNumberOfItems((prev) => prev + delta);
          }
        },
      );
  }
}
