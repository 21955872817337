import { useLocation } from 'react-router-dom';

import { AVAILABLE_LANGUAGES } from '@/constants/i18n';

const stringifiedAvailableLocales = AVAILABLE_LANGUAGES.map(
  (locale) => `/${locale}`,
).join('|');

function getPathnameWithoutLocale(pathname: string) {
  return pathname.replace(new RegExp(stringifiedAvailableLocales, 'i'), '');
}

export default function useFromURL() {
  const location = useLocation();
  return `${getPathnameWithoutLocale(location.pathname)}${location.search}`;
}
