import { Button, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useDeleteCustomObjectMutation } from '@/api/customObject';
import AlertDialog from '@/components/AlertDialog';
import useSnackbar from '@/hooks/useSnackbar';

export default function DeleteCustomObject({
  id,
  onClose,
}: {
  onClose: () => void;
  id: string;
}) {
  const { t } = useTranslation();
  const deleteCustomObject = useDeleteCustomObjectMutation();
  const snackbar = useSnackbar();
  return (
    <AlertDialog
      onClose={onClose}
      componentProps={{
        dialog: {
          'aria-labelledby': 'delete-custom-object',
          'aria-describedby': 'delete-custom-object',
        },
      }}
      renderDialogActions={({ closeModal }) => (
        <Button
          variant="contained"
          onClick={() => {
            deleteCustomObject.mutate(id, {
              onSuccess: () => {
                snackbar.info(
                  t('settings.custom-object.delete-object-success', {
                    defaultValue: 'You have deleted the selected custom object',
                  }),
                );
              },
              onError: () => {
                snackbar.error(t('general.generic-toast-error'));
              },
            });
            closeModal();
          }}
        >
          {t('settings.custom-object.continue', {
            defaultValue: 'Continue',
          })}
        </Button>
      )}
      title={t('settings.custom-object.delete-dialog.title', {
        defaultValue: 'Delete custom object?',
      })}
      description={t('settings.custom-object.delete-dialog.description', {
        defaultValue:
          'Deleting the selected custom object will also delete all contact details associated with the custom object, and this cannot be undone',
      })}
      renderButton={({ setOpen }) => (
        <MenuItem onClick={setOpen}>
          {t('settings.custom-object.delete', {
            defaultValue: 'Delete',
          })}
        </MenuItem>
      )}
    />
  );
}
