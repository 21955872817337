import { injectable } from 'inversify';
import { Subject } from 'rxjs';

import { DataSourceManager } from '../data-sources/data-source-manager';
import {
  ConversationMessageDataSource,
  GetConversationMessagesDataSourceFilter,
} from './conversation-message-data-source';

@injectable()
export class ConversationMessageDataSourceManager extends DataSourceManager<
  ConversationMessageDataSource,
  GetConversationMessagesDataSourceFilter
> {
  private userInteracted = new Subject<boolean>();

  public setUserInteracted() {
    this.userInteracted.next(true);
  }

  public getUserInteracted$() {
    return this.userInteracted.asObservable();
  }
}
