import { FilledInput, Input, InputProps, OutlinedInput } from '@mui/material';
import { TextFieldVariants } from '@mui/material/TextField/TextField';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

export interface NumberInputProps extends Omit<InputProps, 'inputProps'> {
  variant?: TextFieldVariants;
  inputProps?: NumericFormatProps;
}

export function NumberInput(props: NumberInputProps) {
  const { variant = 'outlined', inputProps, ...rest } = props;

  const InputComponent =
    variant === 'standard'
      ? Input
      : variant === 'filled'
      ? FilledInput
      : OutlinedInput;

  return (
    <InputComponent
      inputComponent={NumericFormat as any}
      // @ts-expect-error incorrect typings
      inputProps={{
        valueIsNumericString: true,
        thousandSeparator: true,
        ...inputProps,
      }}
      {...rest}
    />
  );
}
