import { Stack, Tooltip } from '@mui/material';
import {
  CellContext,
  ColumnHelper,
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import dayjs from 'dayjs';
import { MutableRefObject, useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';

import Icon from '@/components/Icon';

import {
  ObjectNameCell,
  StatusToggleCell,
} from '../CustomObjectListTable/cells';
import { CustomObject } from '../type';

export default function useCustomObjectTable(data: CustomObject[]) {
  const { t } = useTranslation();
  const columnHelper = createColumnHelper<CustomObject>();
  const columns = useMemo(() => getColumns({ t, columnHelper }), [t]);
  return useReactTable({
    data,
    getCoreRowModel: getCoreRowModel(),
    columns,
    getRowId: (row) => row.id,
  });
}

function getColumns({
  t,
  columnHelper,
}: {
  t: TFunction;
  columnHelper: ColumnHelper<CustomObject>;
}) {
  return [
    columnHelper.accessor('name', {
      header: () =>
        t('settings.custom-object.table-columns.object-name', {
          defaultValue: 'Object name',
        }),
      cell: (info: unknown) => {
        const infoCasted = info as CellContext<CustomObject, string> & {
          rowRef: MutableRefObject<HTMLElement | null>;
        };
        return infoCasted.rowRef ? (
          <ObjectNameCell
            id={infoCasted.row.id}
            name={infoCasted.getValue()}
            rowRef={infoCasted.rowRef}
            customObject={infoCasted.row.original}
          />
        ) : (
          infoCasted.getValue()
        );
      },
    }),
    columnHelper.accessor('displayedId', {
      header: () =>
        t('settings.custom-object.table-columns.object-id', {
          defaultValue: 'Object ID',
        }),
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('isEnabled', {
      header: () => (
        <Stack direction="row" alignItems="center" pl="5px">
          {t('settings.custom-object.table-columns.status', {
            defaultValue: 'Status',
          })}
          <Tooltip
            title={t('settings.custom-object.table-columns.status-tooltip', {
              defaultValue:
                'You can choose to hide or show the custom object to be displayed on SleekFlow. This will apply across the platform.',
            })}
          >
            <Stack
              sx={{
                ml: '8px',
                ':hover': {
                  cursor: 'pointer',
                },
              }}
            >
              <Icon icon="info-circle" size={16} sx={{ color: 'gray.70' }} />
            </Stack>
          </Tooltip>
        </Stack>
      ),
      cell: (info) => {
        return (
          <StatusToggleCell
            value={info.getValue() ? 'show' : 'hide'}
            customObject={info.row.original}
          />
        );
      },
    }),
    columnHelper.accessor('updatedAt', {
      header: () => {
        return t('settings.custom-object.table-columns.last-updated', {
          defaultValue: 'Last updated',
        });
      },
      cell: (info) => dayjs(info.getValue()).format('DD MMM YYYY HH:mm'),
    }),
  ];
}
