import {
  Button,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { motion } from 'framer-motion';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';
import { useHoverDirty } from 'react-use';

import { useUpdateCustomObjectMutation } from '@/api/customObject';
import Icon from '@/components/Icon';
import { LinkOverlay } from '@/components/LinkOverlay';
import { ROUTES } from '@/constants/navigation';
import useFromURL from '@/hooks/useFromURL';
import { useMenuAnchor } from '@/hooks/useMenuAnchor';
import { useRouteWithLocale } from '@/hooks/useRouteWithLocale/useRouteWithLocale';
import useSnackbar from '@/hooks/useSnackbar';

import { toApiUpdateCustomObject } from '../adapters';
import { truncateObjectName } from '../shared/utils';
import { CustomObject } from '../type';
import DeleteCustomObject from './DeleteCustomObject';
import { RenameCustomObject } from './RenameDialog';

function updateCustomObjectIsEnabled(
  customObject: CustomObject,
  isEnabled: boolean,
) {
  return {
    ...toApiUpdateCustomObject(customObject),
    is_enabled: isEnabled,
  };
}

export function StatusToggleCell({
  value,
  customObject,
}: {
  value: string;
  customObject: CustomObject;
}) {
  const { t } = useTranslation();
  const { anchorEl, open, handleAnchorClick, handleAnchorClose } =
    useMenuAnchor();
  const snackbar = useSnackbar();
  const updateCustomObject = useUpdateCustomObjectMutation();
  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        component={Button}
        onClick={handleAnchorClick}
        endIcon={<Icon icon={open ? 'chevron-up' : 'chevron-down'} size={16} />}
        sx={{
          p: 0,
          ':hover': {
            backgroundColor: 'transparent',
          },
        }}
      >
        <Chip
          label={
            value === 'show'
              ? t('settings.custom-object.show', { defaultValue: 'show' })
              : t('settings.custom-object.hide', { defaultValue: 'hide' })
          }
          sx={{ pointerEvents: 'none' }}
          color={value === 'show' ? 'forest' : 'gray'}
        />
      </Stack>
      <Menu open={open} anchorEl={anchorEl} onClose={handleAnchorClose}>
        <MenuItem
          selected={value === 'disabled'}
          onClick={() => {
            customObject.isEnabled &&
              updateCustomObject.mutate(
                updateCustomObjectIsEnabled(customObject, false),
                {
                  onSuccess: () =>
                    snackbar.info(
                      t('settings.custom-object.toggle-to-disable', {
                        defaultValue:
                          'You have set the object {objectName} to “Hide”',
                        objectName: truncateObjectName(customObject.name),
                      }),
                    ),
                },
              );
            handleAnchorClose();
          }}
        >
          <Chip
            label={t('settings.custom-object.hide', {
              defaultValue: 'hide',
            })}
            color="gray"
            sx={{ pointerEvents: 'none' }}
          />
        </MenuItem>
        <MenuItem
          selected={
            value === t('settings.custom-object.hide', { defaultValue: 'hide' })
          }
          onClick={() => {
            !customObject.isEnabled &&
              updateCustomObject.mutate(
                updateCustomObjectIsEnabled(customObject, true),
                {
                  onSuccess: () =>
                    snackbar.info(
                      t('settings.custom-object.toggle-to-enable', {
                        defaultValue:
                          'You have set the object {objectName} to “Show”',
                        objectName: truncateObjectName(customObject.name),
                      }),
                    ),
                },
              );
            handleAnchorClose();
          }}
        >
          <Chip
            label={t('settings.custom-object.show', {
              defaultValue: 'show',
            })}
            color="forest"
            sx={{ pointerEvents: 'none' }}
          />
        </MenuItem>
      </Menu>
    </>
  );
}

export function ObjectNameCell({
  name,
  id,
  rowRef,
  customObject,
}: {
  name: string;
  id: string;
  rowRef: MutableRefObject<HTMLElement | null>;
  customObject: CustomObject;
}) {
  const textElementRef = useRef<HTMLElement | null>(null);
  const { open, handleAnchorClick, handleAnchorClose, anchorEl } =
    useMenuAnchor();
  const routeTo = useRouteWithLocale();
  const fromURL = useFromURL();
  const isHovering = useHoverDirty(rowRef);
  const [textOverflown, setTextOverflown] = useState(false);

  useEffect(() => {
    const text = textElementRef.current!;
    setTextOverflown(text.scrollWidth > text.clientWidth);
  }, []);

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Tooltip
        title={name}
        disableInteractive={!textOverflown}
        disableHoverListener={!textOverflown}
      >
        <LinkOverlay
          component={Link}
          style={{
            textDecoration: 'none',
            color: 'inherit',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          to={routeTo(
            generatePath(ROUTES.settingsCustomObjectEdit, {
              id,
            }),
          )}
          state={{
            from: fromURL,
          }}
        >
          <Typography
            ref={textElementRef}
            color="darkBlue.90"
            variant="body2"
            sx={(theme) => ({
              ...theme.typography.ellipsis,
            })}
          >
            {name}
          </Typography>
        </LinkOverlay>
      </Tooltip>
      <motion.div
        animate={{
          opacity: isHovering || open ? 1 : 0,
        }}
        initial={{ opacity: 0 }}
        transition={{
          duration: 0.25,
        }}
      >
        <IconButton onClick={handleAnchorClick}>
          <Icon icon="dots-vertical" sx={{ color: 'gray.70' }} />
        </IconButton>
        <Menu
          keepMounted
          MenuListProps={{
            variant: 'menu',
          }}
          open={open}
          anchorEl={anchorEl}
          onClose={handleAnchorClose}
        >
          <RenameCustomObject
            onClose={handleAnchorClose}
            customObject={customObject}
            key={customObject.name}
          />
          <DeleteCustomObject onClose={handleAnchorClose} id={id} />
        </Menu>
      </motion.div>
    </Stack>
  );
}
