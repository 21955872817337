import { Container } from 'inversify';

import { TenantService } from './tenant.service';

function loadDeps(container: Container) {
  container
    .bind<TenantService>(TenantService)
    .to(TenantService)
    .inSingletonScope();
}

export const Tenants = {
  loadDeps,
};
