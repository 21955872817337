import { useMutation } from '@tanstack/react-query';

import { SalesforceIntegration } from '@/pages/Integrations/vendors/Salesforce/shared/types';

import { useAxios } from './axiosClient';
import { CrmIntegrationsResponse, useCrmHubIntegrations } from './crmHub';

export function useSalesforceIntegrations() {
  return useCrmHubIntegrations('salesforce-integrator', {
    suspense: false,
    select: (data: CrmIntegrationsResponse): SalesforceIntegration[] =>
      data.connections.map((item) => ({
        id: item.id,
        name: item.name,
        environment:
          item.environment === 'production' ? 'production' : 'sandbox',
        organizationId: item.organization_id,
        status: item.is_active
          ? item.is_api_request_limit_exceeded
            ? 'restricted'
            : 'connected'
          : 'disconnected',
      })),
  });
}

type RenameResponse = {
  connection: {
    id: string;
    sleekflow_company_id: string;
    organization_id: string;
    name: string;
    environment: 'production' | 'sandbox';
    is_active: boolean;
  };
};

export function useSalesforceChannelRename() {
  const axios = useAxios();

  return useMutation({
    mutationFn: async (connection: SalesforceIntegration) => {
      const result = await axios.post<RenameResponse>(
        '/CrmHub/RenameProviderConnection',
        {
          provider_name: 'salesforce-integrator',
          provider_connection_id: connection.id,
          name: connection.name,
        },
      );

      return result.data;
    },
  });
}

export function useSalesforceChannelDelete() {
  const axios = useAxios();

  return useMutation({
    mutationFn: async (connection: SalesforceIntegration) => {
      return await axios.post('/CrmHub/DeleteProviderConnection', {
        provider_name: 'salesforce-integrator',
        provider_connection_id: connection.id,
      });
    },
  });
}
