import { array, mixed, number, object, string } from 'yup';

import { PROPERTY_TYPE } from '@/pages/Settings/SettingsCustomObject/type';

import { FILTER_OPERATORS } from '../FilterCustomObjectData/rules';

export function validateFilterFromUrlSchema(propertyIds: string[]) {
  return array().of(
    object({
      field: string().oneOf(propertyIds).required(),
      operator: string().oneOf(FILTER_OPERATORS).required(),
      value: string()
        .required()
        .when(['operator', 'type'], ([operator, type]) => {
          if (operator === 'isNotEmpty' || operator === 'isEmpty') {
            return mixed().notRequired();
          }
          if (type === 'number' || type === 'decimalNumber') {
            return number().required();
          }
          if (type === 'multiSelection') {
            return array().required().min(1);
          }
          return string().required().min(1);
        }),
      type: string().oneOf(PROPERTY_TYPE).required(),
    }),
  );
}

export function getFilterDataSchema() {
  return array().of(
    object({
      field: string(),
      operator: string().when('field', ([field]) => {
        return field ? string().oneOf(FILTER_OPERATORS).required() : string();
      }),
      value: string().when(['operator', 'type'], ([operator, type]) => {
        if (!operator && !type) return string();
        if (operator === 'isNotEmpty' || operator === 'isEmpty') {
          return mixed().notRequired();
        }
        if (type === 'number' || type === 'decimalNumber') {
          return number().required();
        }
        if (type === 'multiSelection') {
          return array().required().min(1);
        }
        return string().required().min(1);
      }),
      type: string().when('field', ([field]) => {
        return field ? string().oneOf(PROPERTY_TYPE).required() : string();
      }),
    }),
  );
}
