import {
  Dialog,
  FormControl,
  FormHelperText,
  InputLabel,
  Stack,
  DialogContentText,
  Typography,
  DialogTitle,
  DialogActions,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useHardDeleteUserProfilesMutation } from '@/api/userProfile';
import { Avatar } from '@/components/Avatar';
import { Button as LoadingButton } from '@/components/Button';
import Icon from '@/components/Icon';
import { IconButton } from '@/components/IconButton';
import { NumberInput } from '@/components/NumberInput';
import useSnackbar from '@/hooks/useSnackbar';

export default function HardDeleteContacts({
  selectedContacts,
  onHardDelete,
  renderButton,
  onSuccessSnackbarMessage,
}: {
  selectedContacts: string[];
  onHardDelete?: () => void;
  renderButton: ({
    setOpen,
    isOpen,
  }: {
    setOpen: () => void;
    isOpen: boolean;
  }) => React.ReactNode;
  onSuccessSnackbarMessage?: string;
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [inputNum, setInputNum] = useState<number | null>(null);
  const hardDeletedUserProfiles = useHardDeleteUserProfilesMutation();
  const snackbar = useSnackbar();
  const showInputError =
    inputNum !== null && inputNum !== selectedContacts.length;

  const handleHardDeletedUserProfiles = () => {
    hardDeletedUserProfiles.mutate(selectedContacts, {
      onSuccess: () => {
        setOpen(false);
        snackbar.info(
          onSuccessSnackbarMessage
            ? onSuccessSnackbarMessage
            : t('deleted-contact.success-hard-delete-with-number', {
                defaultValue:
                  'You have permanently deleted {count, plural, one {# contact} other {# contacts}}',
                count: selectedContacts.length,
              }),
        );
        onHardDelete?.();
      },
      onError: () => {
        setOpen(false);
        snackbar.error(
          t('deleted-contact.hard-delete-error', {
            defaultValue:
              'Unable to process the deletion request as the system is still handling your previous request. Please try again later.',
          }),
        );
      },
    });
  };

  return (
    <>
      {renderButton({ setOpen: () => setOpen(true), isOpen: open })}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Stack justifyContent="space-between" borderRadius="8px">
          <Stack spacing={2} direction="row">
            <Stack spacing="16px" sx={{ marginTop: '-100px' }}>
              <DialogTitle
                sx={{
                  marginTop: '-5px',
                  padding: '32px 32px 0 32px',
                }}
                component={Stack}
                display="flex"
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Stack direction="row" alignItems="center" spacing={3}>
                  <Avatar color="lightRed" size={44}>
                    <Icon icon="alert-triangle" />
                  </Avatar>
                  <Typography variant="headline2">
                    {t('deleted-contact.hard-delete-dialog.title', {
                      defaultValue:
                        'Permanently delete {count, plural, one {# contact} other {# contacts}}?',
                      count: selectedContacts.length,
                    })}
                  </Typography>
                </Stack>

                <IconButton
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <Icon icon="x-close" />
                </IconButton>
              </DialogTitle>
              <Stack
                spacing={3}
                sx={{
                  padding: '0 40px 32px 40px',
                  width: '620px',
                }}
              >
                <DialogContentText component={Typography}>
                  {t('deleted-contact.hard-delete-dialog.content', {
                    defaultValue:
                      'Before proceeding, be aware that this action is irreversible, and all related information will be lost.',
                  })}
                </DialogContentText>
                <FormControl error={showInputError}>
                  <InputLabel>
                    {t('deleted-contact.hard-delete-dialog.input-label', {
                      defaultValue:
                        'Input the number of contacts to be permanently deleted to proceed',
                    })}
                  </InputLabel>
                  <NumberInput
                    inputProps={{
                      placeholder: t(
                        'deleted-contact.hard-delete-dialog.place-holder',
                        {
                          defaultValue: 'For example: 4',
                        },
                      ),
                      value: inputNum,
                      isAllowed: (values) => {
                        return values.formattedValue.length <= 20;
                      },
                      onValueChange: (values) => {
                        setInputNum(values.floatValue ?? 0);
                      },
                      decimalScale: 0,
                    }}
                  />
                  {showInputError && (
                    <FormHelperText>
                      {t('deleted-contact.hard-delete-dialog.error-message', {
                        defaultValue: 'Incorrect input. Please try again',
                      })}
                    </FormHelperText>
                  )}
                </FormControl>
                <DialogActions sx={{ padding: '0', marginTop: '32px' }}>
                  <LoadingButton
                    disabled={inputNum !== selectedContacts.length}
                    variant="contained"
                    size="lg"
                    onClick={handleHardDeletedUserProfiles}
                    loading={hardDeletedUserProfiles.isLoading}
                  >
                    {t('deleted-contact.hard-delete-dialog.delete-button', {
                      defaultValue: 'Delete',
                    })}
                  </LoadingButton>
                </DialogActions>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Dialog>
    </>
  );
}
