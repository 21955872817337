export const SORT_DIRECTION_MAP = {
  noOrder: 'noOrder',
  desc: 'desc',
  asc: 'asc',
} as const;

export const SORT_DIRECTION = {
  [SORT_DIRECTION_MAP.noOrder]: 0,
  [SORT_DIRECTION_MAP.asc]: 1,
  [SORT_DIRECTION_MAP.desc]: 2,
};

export const SORT_DIRECTION_ICON = {
  noOrder: 'chevron-selector-vertical',
  desc: 'chevron-down',
  asc: 'chevron-up',
} as const;

export const SORTABLE_COLUMNS = {
  name: 'name',
  deletedBy: 'deletedBy',
  deletedOn: 'deletedOn',
  daysRemaining: 'daysRemaining',
};

export const TABLE_COLUMNS = {
  email: 'email',
  phoneNumber: 'phoneNumber',
  ...SORTABLE_COLUMNS,
};

export const SEARCH_PARAMS = {
  orderBy: 'orderBy',
  direction: 'direction',
  page: 'page',
};
