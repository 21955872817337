import { Button, Typography } from '@mui/material';

import { IconNames } from '@/assets/icomoon/icon';

import Icon from '../Icon';

export default function TableActionBarItem({
  icon,
  label,
  onClick,
}: {
  icon?: IconNames;
  label: string;
  onClick?: () => void;
}) {
  return (
    <Button
      variant="text"
      onClick={onClick}
      size="compact-small"
      sx={{
        color: 'darkBlue.70',
        padding: 0,

        '& > .MuiButton-startIcon': {
          marginRight: '4px',
        },
      }}
      startIcon={icon ? <Icon size={16} icon={icon} /> : undefined}
    >
      <Typography variant="menu1">{label}</Typography>
    </Button>
  );
}
