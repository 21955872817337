import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useQuery } from '@tanstack/react-query';

import { useAxios } from '@/api/axiosClient';

export const auth0AccountKeys = createQueryKeys('auth0Account', {
  getAuth0AccountIsCompanyRegistered: null,
});

type CompanyRegisteredResponse = {
  data: {
    is_company_registered: boolean;
    http_status_code: number;
    success: boolean;
  };
  success: boolean;
};
export const useAuth0AccountIsCompanyRegisteredQuery = (options?: {
  suspense?: boolean;
}) => {
  const url = '/v1/tenant-hub/Register/Companies/IsCompanyRegistered';
  const axiosClient = useAxios();
  return useQuery(
    auth0AccountKeys.getAuth0AccountIsCompanyRegistered,
    async ({ signal }) => {
      const response = await axiosClient.post<CompanyRegisteredResponse>(
        import.meta.env.VITE_SLEEKFLOW_API_BASE_URL + url,
        {
          signal,
        },
      );
      return response.data;
    },
    {
      suspense: options?.suspense,
      meta: {
        url,
        description: 'Check if company is registered',
      },
    },
  );
};
