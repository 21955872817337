import { UseMutationResult } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import { AnyObject, TestContext, ValidationError } from 'yup';

import { FilterParam } from '@/api/customObject';

import { CustomObjectProperty } from '../type';

export const DROPDOWN_TYPE = ['singleSelection', 'multiSelection'];

export const getNormalizedName = (name: string) => {
  const patternSanitize = /[^a-zA-Z0-9]/g;
  const patternMultiUnderscores = /\s+/g;
  const sanitized = name.toLowerCase().replace(patternSanitize, ' ').trim();

  return sanitized.replace(patternMultiUnderscores, '_');
};

export const isNameContainSpecialChars = (name: string) => {
  const regex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
  return regex.test(name);
};

export const isValueUnique = <T>(array: T[], mapper: (value: T) => any) => {
  return array.length === new Set(array.map(mapper)).size;
};

export function findDuplicateIndices(elements: CustomObjectProperty[]) {
  const names = new Map<string, number>();
  const displayedIds = new Map<string, number>();
  const duplicateNames: number[] = [];
  const duplicateKeys: number[] = [];

  elements.forEach((element, index) => {
    if (names.has(element.name)) {
      duplicateNames.push(index);
    } else {
      names.set(element.name, index);
    }

    if (displayedIds.has(element.displayedId)) {
      duplicateKeys.push(index);
    } else {
      displayedIds.set(element.displayedId, index);
    }
  });

  return {
    duplicateKeys,
    duplicateNames,
  };
}

export function findModifiedFields(obj: Record<string, any>): boolean {
  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      if (findModifiedFields(obj[key])) {
        return true;
      }
    } else if (obj[key] === true) {
      return true;
    }
  }
  return false;
}
export function isUniqueIdValid(id: string) {
  const regex = /^(?!.*__)[a-z0-9]([a-z0-9_]*[a-z0-9])?$/;
  return regex.test(id);
}

export function checkIsUniqueIdValid(
  value: string,
  ctx: TestContext<AnyObject>,
  t: TFunction,
) {
  return isUniqueIdValid(value)
    ? true
    : ctx.createError({
        message: t('settings.custom-object.form-error.invalid-id', {
          defaultValue:
            'You can only use lowercase characters, numbers and underscores “_”',
        }),
      });
}

export function checkPropertyNameUniqueness(
  value:
    | {
        options?:
          | {
              value?: string | undefined;
            }[]
          | undefined;
        name: string;
        displayedId: string;
        type: string;
      }[]
    | undefined,
  ctx: TestContext<AnyObject>,
  t: TFunction,
) {
  const { duplicateKeys, duplicateNames } = findDuplicateIndices([
    ctx.parent.primaryProperty,
    ...ctx.parent.properties,
  ]);

  const uniqueKeyErrors = duplicateKeys.map((index) => {
    return new ValidationError(
      t('settings.custom-object.form-error.duplicate-id', {
        defaultValue: 'Duplicated unique identifier.',
      }),
      value,
      `${ctx.path}[${index - 1}].displayedId`,
    );
  });
  const uniqueNameErrors = duplicateNames.map((index) => {
    return new ValidationError(
      t('settings.custom-object.form-error.duplicate-name', {
        defaultValue: 'Duplicated name',
      }),
      value,
      `${ctx.path}[${index - 1}].name`,
    );
  });
  const errors = [...uniqueKeyErrors, ...uniqueNameErrors];

  if (errors.length > 0) {
    throw new ValidationError(errors);
  }
  return true;
}

export async function checkCustomObjectNameExist(
  value: string,
  ctx: TestContext<AnyObject>,
  filterCustomObjectList: UseMutationResult<
    any,
    unknown,
    {
      limit: number;
      filtersWithAndLogic?: FilterParam[] | undefined;
      filtersWithOrLogic?: FilterParam[] | undefined;
    },
    unknown
  >,
  type: 'edit' | 'create',
) {
  try {
    const response = await filterCustomObjectList.mutateAsync({
      limit: 1,
      filtersWithOrLogic: [
        {
          field: 'display_name',
          operator: '=',
          value: value,
        },
      ],
    });

    if (type === 'create') {
      return response.count === 0;
    } else {
      return response.count === 0
        ? true
        : ctx.parent.id === response.schemas[0].id;
    }
  } catch (_e) {
    return false;
  }
}

export function createEmptyProperty() {
  return {
    id: '',
    name: '',
    displayedId: '',
    type: 'text' as const,
    isRequired: true,
    isSearchable: true,
    isPinned: true,
    isVisible: true,

    createdAt: dayjs().toISOString(),
  };
}

export function truncateObjectName(objectName: string) {
  if (objectName.length > 10) {
    return objectName.substring(0, 25) + '...';
  } else {
    return objectName;
  }
}
