import { CircularProgress, Stack, StackProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function CircularLoader(props: StackProps) {
  const { t } = useTranslation();

  return (
    <Stack
      flex={1}
      justifyContent="center"
      alignItems="center"
      spacing={1}
      {...props}
    >
      <CircularProgress />
      <Typography variant="body2">{t('general.loading')}</Typography>
    </Stack>
  );
}
