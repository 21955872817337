import { createQueryKeys } from '@lukemorales/query-key-factory';
import {
  InfiniteData,
  QueryKey,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { useAxios } from '@/api/axiosClient';
import {
  AuditLogsFilterType,
  AuditLogsGetUserProfileAuditLogsResponse,
  AuditLogsUpdatedDataResponse,
  AuditLogResponseDatum,
} from '@/api/types';

export type AuditLogsGetUserProfileAuditLogsArgs = {
  sleekflow_user_profile_id: string;
  continuation_token?: string;
  filters: {
    types?: AuditLogsFilterType[] | null;
    has_sleekflow_staff_id?: boolean;
  };
  limit?: number;
};

export const auditHubKeys = createQueryKeys('auditHub', {
  getAuditLogsGetUserProfileAuditLogs: (
    props: AuditLogsGetUserProfileAuditLogsArgs,
    options: { type?: 'query' | 'infinite' } = {},
  ) => [props, options],
  getInboxAuditLogs: (props: { profileId: string }) => [props],
});

export function useAuditLogsGetUserProfileAuditLogsInfiniteQuery<
  T = AuditLogsGetUserProfileAuditLogsResponse,
>(
  {
    sleekflow_user_profile_id,
    filters,
    limit = 10,
  }: Omit<AuditLogsGetUserProfileAuditLogsArgs, 'continuation_token'>,
  options?: {
    enabled?: boolean;
    select?:
      | ((
          data: InfiniteData<AuditLogsGetUserProfileAuditLogsResponse>,
        ) => InfiniteData<T>)
      | undefined;
  },
) {
  const url = 'AuditHub/AuditLogs/GetUserProfileAuditLogsV2';
  const axiosClient = useAxios();
  return useInfiniteQuery(
    auditHubKeys.getAuditLogsGetUserProfileAuditLogs(
      {
        sleekflow_user_profile_id,
        filters,
        limit,
      },
      {
        type: 'infinite',
      },
    ),
    async ({ signal, pageParam }) => {
      const response =
        await axiosClient.post<AuditLogsGetUserProfileAuditLogsResponse>(
          url,
          {
            sleekflow_user_profile_id,
            filters,
            continuation_token: pageParam,
            limit,
          },
          {
            signal,
          },
        );
      return response.data;
    },
    {
      enabled: options?.enabled,
      select: options?.select,
      getNextPageParam: (lastPage) => {
        return lastPage.data?.next_continuation_token;
      },
      meta: {
        url,
        description: 'Gets user activity logs',
      },
    },
  );
}

export function useAuditLogsLimitedQuery<TData>(props: {
  profileId: string;
  types: AuditLogsFilterType[];
  limit: number;
  select: (d: AuditLogsGetUserProfileAuditLogsResponse) => TData;
  queryKey?: QueryKey;
}) {
  const axios = useAxios();
  const defaultQueryKey = auditHubKeys.getAuditLogsGetUserProfileAuditLogs({
    sleekflow_user_profile_id: props.profileId,
    filters: { types: props.types },
    limit: props.limit,
  });
  return useQuery({
    queryKey: props.queryKey ?? defaultQueryKey,
    queryFn: async () => {
      return await axios.post<AuditLogsGetUserProfileAuditLogsResponse>(
        'AuditHub/AuditLogs/GetUserProfileAuditLogsV2',
        {
          sleekflow_user_profile_id: props.profileId,
          limit: props.limit,
          filters: { types: props.types },
        },
      );
    },
    select: (d) => props.select(d.data),
  });
}

type AuditLogsCreateStaffManualAddedLogArgs = {
  sleekflow_user_profile_id: string;
  audit_log_text: string;
};

export function useAuditLogsCreateStaffManualAddedLogMutation(props: {
  queryKey: QueryKey;
}) {
  const axiosClient = useAxios();
  const url = 'AuditHub/AuditLogs/CreateStaffManualAddedLog';
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: AuditLogsCreateStaffManualAddedLogArgs) => {
      const response = axiosClient.post<AuditLogsCreateStaffManualAddedLogArgs>(
        url,
        {
          sleekflow_user_profile_id: data.sleekflow_user_profile_id,
          audit_log_text: data.audit_log_text,
        },
      );

      return response;
    },
    onSuccess: async () => {
      queryClient.invalidateQueries(props.queryKey);
    },
  });
}

export function useUpdateUserActivityMutation() {
  const url = `/AuditHub/AuditLogs/UpdateStaffManualAddedLog`;
  const axiosClient = useAxios();
  const queryClient = useQueryClient();

  return useMutation<
    AuditLogsUpdatedDataResponse['data'],
    unknown,
    { remarkId: string; userProfileId: string; remarks: string },
    unknown
  >({
    mutationFn: async (data) => {
      const response = await axiosClient.post(url, {
        id: data.remarkId,
        sleekflow_user_profile_id: data.userProfileId,
        audit_log_text: data.remarks,
      });
      return response.data.data;
    },
    onSuccess: async (data, vars) => {
      const queryKey = auditHubKeys.getInboxAuditLogs({
        profileId: vars.userProfileId,
      });
      await queryClient.cancelQueries(queryKey);

      // write the new cache here
      queryClient.setQueryData<{
        data: AuditLogsGetUserProfileAuditLogsResponse;
      }>(queryKey, (cachedValue) => {
        if (!cachedValue) {
          return undefined;
        }
        return updateCachedInboxLogs(cachedValue, data.user_profile_audit_log);
      });
    },
  });
}

export function useDeleteUserActivityMutation() {
  const url = `/AuditHub/AuditLogs/DeleteStaffManualAddedLog`;
  const axiosClient = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (vars: { remarkId: string; userProfileId: string }) => {
      await axiosClient.post(url, {
        id: vars.remarkId,
        sleekflow_user_profile_id: vars.userProfileId,
      });
      return;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(
        auditHubKeys.getAuditLogsGetUserProfileAuditLogs._def,
      );
      queryClient.invalidateQueries(auditHubKeys.getInboxAuditLogs._def);
    },
  });
}

function updateCachedInboxLogs(
  cachedValue: {
    data: AuditLogsGetUserProfileAuditLogsResponse;
  },
  input: AuditLogResponseDatum,
): { data: AuditLogsGetUserProfileAuditLogsResponse } {
  const logsUpdated = cachedValue.data.data.user_profile_audit_logs.reduce<
    AuditLogResponseDatum[]
  >((acc, next) => {
    if (next.id === input.id) {
      return [
        ...acc,
        {
          ...input,
        },
      ];
    }
    return [...acc, next];
  }, []);

  return {
    ...cachedValue,
    data: {
      ...cachedValue.data,
      data: {
        ...cachedValue.data.data,
        user_profile_audit_logs: logsUpdated,
      },
    },
  };
}
