import {
  Box,
  ListItem,
  Stack,
  styled,
  Theme,
  Tooltip,
  TooltipProps,
} from '@mui/material';
import { TFunction } from 'i18next';
import { useInjection } from 'inversify-react';
import { useObservableState } from 'observable-hooks';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, To } from 'react-router-dom';

import { useTicketingConfig } from '@/api/ticketing';
import { RoleType } from '@/api/types';
import { ReactComponent as CircleLogo } from '@/assets/logo/sleekflow-logo-circle.svg';
import { ScrollArea } from '@/components/ScrollArea';
import { ROUTES } from '@/constants/navigation';
import { useAISettingsRoleBasedAccessControl } from '@/pages/AiSettings/hooks/useAISettingsRoleBasedAccessControl';
import AnalyticsNav from '@/pages/Analytics/analyticsNav';
import { useAnalyticsRoleBasedAccessControl } from '@/pages/Analytics/hooks/useAnalyticsRoleBasedAccessControl';
import { useBroadcastRoleBasedAccessControl } from '@/pages/Broadcasts/hooks/useBroadcastRoleBasedAccessControl';
import useAccessRuleGuard from '@/pages/Contacts/shared/accessRuleGuard/useAccessRuleGuard';
import CustomObjectDataNav from '@/pages/CustomObjectData/CustomObjectDataNav';
import useCustomObjectDataAccessControl from '@/pages/CustomObjectData/hooks/useCustomObjectDataAccessControl';
import { useFlowBuilderRoleBasedAccessControl } from '@/pages/FlowBuilder/hooks/useFlowBuilderRoleBasedAccessControl';
import { useIntegrationsRoleBasedAccessControl } from '@/pages/Integrations/hooks/useIntegrationsRoleBasedAccessControl';
import { TenantService } from '@/services/tenants/tenant.service';
import BackgroundTaskManager from '@/signalr/BackgroundTaskManager/BackgroundTaskManager';
import { useDebugMode } from '@/utils/useDebugMode';

import Icon, { IconProps } from '../Icon';
import SubNavMenu from './SubNavMenu';

export const SIDEBAR_WIDTH = 76;

export type NavItem = {
  to: string;
  label: string;
  icon: IconProps['icon'];
  permission?:
    | keyof ReturnType<typeof useBroadcastRoleBasedAccessControl>
    | keyof ReturnType<typeof useFlowBuilderRoleBasedAccessControl>
    | keyof ReturnType<typeof useAISettingsRoleBasedAccessControl>
    | keyof ReturnType<typeof useIntegrationsRoleBasedAccessControl>
    | keyof ReturnType<typeof useAnalyticsRoleBasedAccessControl>
    | keyof ReturnType<typeof useCustomObjectDataAccessControl>
    | keyof NonNullable<ReturnType<typeof useTicketingConfig>['data']>;
  children?: NavItem[];
  openInNewTab?: boolean;
};

const getNavbarItems = (t: TFunction, isAdminRoleUser: boolean): NavItem[] => [
  ...(isAdminRoleUser
    ? [
        {
          to: ROUTES.channels,
          label: t('nav.channels'),
          icon: 'signal' as const,
        },
      ]
    : []),
  { to: ROUTES.inbox, label: t('nav.inbox'), icon: 'inbox' },
  {
    to: ROUTES.tickets,
    label: t('nav.tickets', 'Tickets'),
    icon: 'ticket',
    permission: 'is_ticket_enabled',
  },
  { to: ROUTES.contacts, label: t('nav.contacts'), icon: 'contacts' },
  {
    to: ROUTES.broadcasts,
    label: t('nav.broadcasts'),
    icon: 'broadcasts',
    permission: 'canViewBroadcastReview',
  },
  {
    to: ROUTES.flowBuilder,
    label: t('nav.flow-builder'),
    icon: 'dataflow-downwards',
    permission: 'canUseFlow',
  },
  {
    to: ROUTES.analytics,
    label: t('nav.analytics'),
    icon: 'analytics',
    permission: 'canViewAnalytics',
  },
  {
    to: ROUTES.integrations.index,
    label: t('nav.integrations'),
    icon: 'puzzle-piece',
    permission: 'canViewIntegrationsSettings',
  },
  {
    to: ROUTES.commerceHub,
    label: t('nav.commerce-hub'),
    icon: 'shopping-cart',
  },
  {
    to: ROUTES.aiSettings,
    label: t('nav.ai-settings'),
    icon: 'star-ai' as const,
    permission: 'canViewAISettings' as const,
  },
  {
    to: ROUTES.customObjectData,
    label: t('nav.custom-object-data'),
    icon: 'database',
    permission: 'canAccessCustomObjectData',
  },
];

const getFooterItems = (t: TFunction): NavItem[] => [
  // TODO: remove when invite users modal is implemented
  { to: ROUTES.invite, label: t('nav.invite-users'), icon: 'users-add' },
  { to: ROUTES.settings, label: t('nav.settings'), icon: 'settings' },
  {
    label: t('nav.help-and-support', { defaultValue: 'help and support' }),
    icon: 'help-circle',
    to: 'help-and-support',
    children: [
      {
        to: 'https://help.sleekflow.io/',
        label: t('nav.help-center', { defaultValue: 'Help center' }),
        icon: 'book-open',
        openInNewTab: true,
      },
      {
        to: 'https://share.hsforms.com/1o8NZAO0mRJuPWLqxeFeDKAcdz4m',
        label: t('nav.submit-ticket', {
          defaultValue: 'Submit support ticket',
        }),
        icon: 'email',
        openInNewTab: true,
      },
    ],
  },
];

export const NavMenuItemToolTip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ),
)({
  '& .MuiTooltip-tooltip': {
    fontSize: 14,
  },
  // Hack to control the offset
  '& .MuiTooltip-tooltipPlacementRight': {
    marginLeft: '8px !important',
  },
});

export default function Navbar() {
  const { t } = useTranslation();

  const broadcastPermission = useBroadcastRoleBasedAccessControl();
  const flowBuilderPermission = useFlowBuilderRoleBasedAccessControl();
  const aiSettingsPermission = useAISettingsRoleBasedAccessControl();
  const integrationsPermission = useIntegrationsRoleBasedAccessControl();
  const analyticsPermission = useAnalyticsRoleBasedAccessControl();
  const accessRulesGuard = useAccessRuleGuard({ suspense: true });
  const tenantService = useInjection(TenantService);
  const customObjectDataPermission = useCustomObjectDataAccessControl();
  const { data: ticketingConfig } = useTicketingConfig({ suspense: true });

  const defaultUserWorkspace$ = useMemo(() => {
    return tenantService.getDefaultUserWorkspace$();
  }, [tenantService]);

  const defaultUserWorkspace = useObservableState(defaultUserWorkspace$);
  const toggleDebugValue = useDebugMode((state) => state.toggleDebugValue);
  const isAdminRoleUser =
    accessRulesGuard.user.data?.roleType === RoleType.ADMIN;

  const rbac = {
    ...broadcastPermission,
    ...flowBuilderPermission,
    ...aiSettingsPermission,
    ...integrationsPermission,
    ...analyticsPermission,
    ...customObjectDataPermission,
    ...ticketingConfig,
  };

  return (
    <Box component="nav" sx={{ zIndex: 1000 }} width={SIDEBAR_WIDTH}>
      <Box
        sx={{
          backgroundColor: 'darkBlue.90',
          height: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
        }}
      >
        <Box
          display="flex"
          height="100%"
          flex={0}
          alignItems="center"
          position="relative"
          padding="16px"
        >
          <Box
            display="flex"
            alignItems="center"
            padding="0 2px"
            overflow="hidden"
            height={40}
            width={1}
            onClick={() => {
              toggleDebugValue();
            }}
          >
            <NavMenuItemToolTip
              title={defaultUserWorkspace?.server_location ?? ''}
              placement="right"
              enterDelay={100}
              enterNextDelay={100}
            >
              <span>
                <CircleLogo width={40} height={40} style={{ flexShrink: 0 }} />
              </span>
            </NavMenuItemToolTip>
          </Box>
        </Box>
        <ScrollArea sx={{ flex: 'auto' }} onDark>
          <Stack spacing="4px" padding="0 16px">
            {getNavbarItems(t, isAdminRoleUser).map((item) => {
              if (
                (item.permission && rbac[item.permission] === undefined) ||
                (item.permission && rbac[item.permission]) ||
                !item.permission
              ) {
                if (item.to === ROUTES.analytics) {
                  return <AnalyticsNav key={item.to} />;
                }
                if (item.to === ROUTES.customObjectData) {
                  return <CustomObjectDataNav key={item.to} />;
                }
                return <NavMenuItem key={item.to} {...item} />;
              }
              return null;
            })}
          </Stack>
        </ScrollArea>
        <Stack spacing="4px" padding="16px" flex={0}>
          <BackgroundTaskManager />
          {getFooterItems(t).map((item) => (
            <NavMenuItem key={item.to} {...item} />
          ))}
        </Stack>
      </Box>
    </Box>
  );
}

export const getNavMenuItemStyles = (theme: Theme, isActive: boolean) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
  borderRadius: '8px',
  width: 'auto',
  color: isActive ? theme.palette.white : theme.palette.gray[90],
  backgroundColor: isActive ? theme.palette.darkBlue[80] : 'transparent',
  overflow: 'hidden',
  '&:hover': {
    backgroundColor: theme.palette.darkBlue[80],
    color: theme.palette.white,
  },
});

function NavMenuItem({
  label,
  icon,
  to,
  children,
}: {
  to: To;
  label: string;
  icon: IconProps['icon'];
  children?: NavItem[];
}) {
  if (children?.length) {
    return (
      <SubNavMenu title={label} icon={icon}>
        {children}
      </SubNavMenu>
    );
  }
  return (
    <NavMenuItemToolTip
      title={label}
      placement="right"
      enterDelay={100}
      enterNextDelay={100}
    >
      <NavLink to={to} style={{ textDecoration: 'none' }}>
        {({ isActive }) => (
          <ListItem
            component="span"
            disablePadding
            sx={(theme) => getNavMenuItemStyles(theme, isActive)}
          >
            <Icon icon={icon} size={20} sx={{ flexShrink: 0 }} />
          </ListItem>
        )}
      </NavLink>
    </NavMenuItemToolTip>
  );
}
