import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { TableCell, TableRow, Theme } from '@mui/material';
import { Cell, Table, flexRender } from '@tanstack/react-table';

import Icon from '@/components/Icon';
import { LinkBox } from '@/components/LinkOverlay';

export default function SortableRow<T>({
  id,
  cells,
}: {
  id: string;
  cells: Cell<T, unknown>[];
  table: Table<T>;
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    setActivatorNodeRef,
    node,
  } = useSortable({
    id,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    backgroundColor: isDragging ? 'blue.20' : 'white',
  };

  return (
    <TableRow
      ref={setNodeRef}
      {...attributes}
      sx={{ cursor: 'pointer', ...style }}
    >
      {isDragging ? (
        <TableCell colSpan={cells.length + 1}></TableCell>
      ) : (
        <>
          <TableCell
            width="40px"
            sx={{
              color: 'gray.30',
              ':hover': {
                cursor: 'grab',
                color: 'gray.90',
              },
            }}
            ref={setActivatorNodeRef}
            {...listeners}
          >
            <Icon icon="drag-and-drop" size={16} />
          </TableCell>
          {cells.map((cell) => {
            return (
              <LinkBox
                key={cell.id}
                component={TableCell}
                sx={(theme: Theme) => ({
                  ...theme.typography.ellipsis,
                  maxWidth: `${cell.column.getSize()}px`,
                })}
              >
                {flexRender(cell.column.columnDef.cell, {
                  ...cell.getContext(),
                  rowRef: node,
                })}
              </LinkBox>
            );
          })}
        </>
      )}
    </TableRow>
  );
}
