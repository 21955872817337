import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { create } from 'zustand';

import { GlobalDialogs } from '@/dialogs';

interface DialogStore {
  id: GlobalDialogs | null;
  open: boolean;
  onOpen: (id: GlobalDialogs) => void;
  onClose: () => void;
}

export const useDialogStore = create<DialogStore>()((set) => ({
  id: null,
  open: false,
  onOpen: (id) => set({ id, open: true }),
  onClose: () => set({ open: false }),
}));

export const useDialog = () => {
  const [_, _setSearchParams] = useSearchParams();
  const { id, open } = useDialogStore();

  const openDialog = useCallback(
    (
      id: GlobalDialogs,
      setSearchParams?: (prevSearchParams: URLSearchParams) => void,
    ) => {
      _setSearchParams((prev) => {
        prev.set('dialog', id);
        setSearchParams?.(prev);
        return prev;
      });
    },
    [_setSearchParams],
  );

  const closeDialog = useCallback(
    (cleanup?: (prevSearchParams: URLSearchParams) => void) => {
      _setSearchParams((prev) => {
        prev.delete('dialog');
        cleanup?.(prev);
        return prev;
      });
    },
    [_setSearchParams],
  );

  return {
    id,
    open,
    openDialog,
    closeDialog,
  };
};
