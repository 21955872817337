import { Button, IconButton, Stack, Typography } from '@mui/material';
import { useCallback, useEffect } from 'react';
import toast from 'react-hot-toast/headless';
import { useTranslation } from 'react-i18next';
import { useRegisterSW } from 'virtual:pwa-register/react';

import Icon from './components/Icon';

export default function AppVersionUpdateToast() {
  const { t } = useTranslation();
  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW: useCallback(
      (swUrl: string, r: ServiceWorkerRegistration | undefined) => {
        console.log('SW Registered: ' + swUrl);
        if (r) {
          setInterval(async () => {
            console.log('SW polling update: ', { ...r });
            if (!(!r.installing && navigator)) return;
            // Don't check for updates if the user is offline
            if ('connection' in navigator && !navigator.onLine) return;
            // Check if service worker exists
            const resp = await fetch(swUrl, {
              cache: 'no-store',
              headers: {
                cache: 'no-store',
                'cache-control': 'no-cache',
              },
            });

            if (resp?.status === 200) {
              await r.update();
            }
            // Check for updates every 15 minutes
          }, 15 * 60 * 1000);
        }
      },
      [],
    ),
    onRegisterError(error) {
      console.error('SW registration error', error);
    },
  });

  useEffect(() => {
    if (needRefresh) {
      toast(
        (_t) => (
          <Stack
            borderLeft={8}
            borderColor="blue.90"
            bgcolor="white"
            width={400}
            alignItems="flex-start"
            borderRadius={1}
            py="16px"
            px="28px"
            gap={2}
            boxShadow={(theme) => theme.shadows[4]}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Typography variant="headline3">
                {t('app-update.title', {
                  defaultValue: 'New App Version Available',
                })}
              </Typography>
              <IconButton
                sx={{
                  width: 20,
                  height: 20,
                }}
                onClick={() => {
                  setNeedRefresh(false);
                  toast.dismiss(_t.id);
                }}
              >
                <Icon icon="x-close" />
              </IconButton>
            </Stack>
            <Typography>
              {t('app-update.description', {
                defaultValue:
                  'A new version of the app is available. Please reload the page to get the latest version.',
              })}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={async () => {
                try {
                  updateServiceWorker(true);
                  setNeedRefresh(false);
                } catch {
                  console.error('Failed to update service worker');
                }
              }}
            >
              {t('app-update.reload-button', {
                defaultValue: 'Reload',
              })}
            </Button>
          </Stack>
        ),
        {
          duration: Infinity,
          id: 'app-update',
        },
      );

      return () => {
        toast.dismiss('app-update');
        setNeedRefresh(false);
      };
    }
  }, [needRefresh, setNeedRefresh, t, updateServiceWorker]);

  return null;
}
