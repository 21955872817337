import { RoleType } from '@/api/types';
import { useMyProfile } from '@/hooks/useMyProfile';

export const useAnalyticsRoleBasedAccessControl = ({
  suspense,
}: { suspense?: boolean } = {}) => {
  const userProfile = useMyProfile({ suspense });

  return {
    // Analytics
    canViewAnalytics: userProfile.data?.roleType === RoleType.ADMIN,
  };
};
