import {
  InputAdornment,
  ListSubheader,
  Menu,
  MenuItem,
  OutlinedInput,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FixedSizeList } from 'react-window';

import { useGetPreviewObjectsQuery } from '@/api/crmHub';
import { Avatar } from '@/components/Avatar';
import Icon from '@/components/Icon';
import { CustomLabelInputProps } from '@/components/Select/CustomLabelInput';
import { useMenuAnchor } from '@/hooks/useMenuAnchor';
import ConversationListVirtualOuterElementType from '@/pages/InboxRXJS/ConversationLists/ConversationListVirtualOuterElementType';
import { getFullName } from '@/utils/messaging';

export default function SelectSalesforceUser({
  providerConnectionId,
  onChange,
  value,
  mappedUsers,
}: {
  providerConnectionId?: string;
  onChange: (value: { displayName: string; email: string; id: string }) => void;
  value: {
    displayName: string;
    email: string;
    id: string;
  };
  mappedUsers: string[];
}) {
  const { t } = useTranslation();

  const salesforceUsers = useGetPreviewObjectsQuery(
    {
      providerName: 'salesforce-integrator',
      entityType: 'User',
      providerConnectionId: providerConnectionId ?? '',
    },
    {
      enabled: !!providerConnectionId,
      select: (data) => {
        return data.objects.map((o) => ({
          displayName: getFullName({
            firstName: o.FirstName,
            lastName: o.LastName,
            fallback: t('general.unknown-label'),
          }),
          email: o.Email,
          id: o.Id,
        }));
      },
    },
  );

  const { anchorEl, open, handleAnchorClick, handleAnchorClose } =
    useMenuAnchor();
  const [keyword, setKeyword] = useState('');

  const searchedUsers = useMemo(
    () =>
      salesforceUsers.data?.filter(
        (user) =>
          !mappedUsers.includes(user.id) &&
          (user.displayName?.toLowerCase()?.includes(keyword.toLowerCase()) ||
            user.email?.toLowerCase()?.includes(keyword.toLowerCase())),
      ),
    [keyword, mappedUsers, salesforceUsers.data],
  );
  const fixedSizeListOuterRef = useRef<HTMLDivElement | null>(null);
  const [searchInputEl, setSearchInputEl] = useState<HTMLInputElement>();

  useEffect(() => {
    if (open && searchInputEl) {
      searchInputEl?.focus();
    }
  }, [open, searchInputEl]);

  return (
    <>
      <OutlinedInput
        sx={() => ({
          width: '260px',
          flexShrink: 0,
          display: 'flex',
          cursor: 'pointer',
          justifyContent: 'space-between',
          '& input': {
            position: 'absolute',
            width: '0 !important',
            opacity: 0,
          },
        })}
        inputComponent={CustomLabelInput}
        inputProps={{
          'aria-hidden': true,
          children: value.id ? (
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{
                color: undefined,
                overflow: 'hidden',
              }}
            >
              <Avatar
                size="small"
                alt={value.displayName}
                sx={{ bgcolor: 'black' }}
              />

              <Stack sx={{ overflow: 'hidden' }}>
                <Typography
                  variant="body1"
                  color="gray.90"
                  sx={(theme) => ({
                    ...theme.typography.ellipsis,
                  })}
                >
                  {value.displayName}
                </Typography>

                <Typography
                  variant="body2"
                  color="gray.80"
                  sx={(theme) => ({
                    ...theme.typography.ellipsis,
                  })}
                >
                  {value.email}
                </Typography>
              </Stack>
            </Stack>
          ) : (
            <Typography variant="body1">
              {t('integrations.salesforce.select', {
                defaultValue: 'Select',
              })}
            </Typography>
          ),
        }}
        onClick={handleAnchorClick}
        value={value}
        endAdornment={
          <Stack direction="row">
            {value.id && (
              <Icon
                icon="x-close"
                onClick={(e) => {
                  e.stopPropagation();
                  onChange({
                    displayName: '',
                    email: '',
                    id: '',
                  });
                }}
              />
            )}
            <Icon
              icon="chevron-down"
              size={20}
              sx={{
                transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                flexShrink: 0,
                p: '2px',
              }}
            />
          </Stack>
        }
      />
      <Menu
        disableAutoFocusItem
        anchorEl={anchorEl}
        open={open}
        onClose={handleAnchorClose}
        elevation={0}
        MenuListProps={{
          sx: {
            width: '260px',
            maxHeight: '400px',
            p: '0 12px 0 0',
          },
        }}
      >
        <ListSubheader
          sx={{
            p: '12px',
          }}
        >
          <TextField
            onFocus={(e) => e.stopPropagation()}
            inputRef={setSearchInputEl}
            fullWidth
            onKeyDown={(e) => {
              if (e.key !== 'Escape') {
                e.stopPropagation();
              }
            }}
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon icon="search" size={16} sx={{ color: 'gray.70' }} />
                </InputAdornment>
              ),
            }}
            onClick={(e) => e.stopPropagation()}
          />
        </ListSubheader>
        {salesforceUsers.isLoading ? (
          <LoadingSkeleton />
        ) : salesforceUsers.data?.length === 0 ? (
          <Stack
            direction="column"
            spacing={3}
            sx={{
              p: '12px 16px',
            }}
          >
            <Typography
              variant="body1"
              fontSize={14}
              sx={{ textAlign: 'center' }}
            >
              {t('inbox.universal-search.empty-result')}
            </Typography>
          </Stack>
        ) : (
          <FixedSizeList
            itemKey={(index, data) => `${data[index].id}-${index}`}
            itemSize={48}
            width="100%"
            height={336}
            overscanCount={25}
            outerRef={fixedSizeListOuterRef}
            itemData={searchedUsers}
            itemCount={searchedUsers?.length ?? 0}
            outerElementType={ConversationListVirtualOuterElementType}
          >
            {({ index, style, data }) => {
              const user = data[index];
              return (
                <MenuItem
                  value={user.id}
                  key={user.id}
                  sx={{ direction: 'row', height: '48px', m: 0 }}
                  onClick={() => {
                    onChange(user);
                    handleAnchorClose();
                  }}
                  style={style}
                >
                  <Avatar
                    sx={{
                      mr: '8px',
                      width: '24px',
                      height: '24px',
                      bgcolor: 'black',
                    }}
                    alt={user.displayName}
                    name={user.displayName}
                  />
                  <Stack>
                    <Typography variant="body1" color="gray.90">
                      {user.displayName}
                    </Typography>

                    <Typography variant="body2" color="gray.80">
                      {user.email}
                    </Typography>
                  </Stack>
                </MenuItem>
              );
            }}
          </FixedSizeList>
        )}
      </Menu>
    </>
  );
}

function LoadingSkeleton() {
  return (
    <Stack height="324px" sx={{ p: '0px 12px' }}>
      {Array.from(Array(5)).map((_, idx) => (
        <Stack
          flexDirection="row"
          gap={1}
          alignItems="center"
          p="6px"
          key={idx}
        >
          <Skeleton width={18} height={18} variant="rectangular" />
          <Skeleton
            variant="circular"
            width={32}
            height={32}
            sx={{ borderRadius: '50%' }}
          />
          <Stack flex={1}>
            <Skeleton variant="text" />
            <Skeleton variant="text" />
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
}

const CustomLabelInput = forwardRef<HTMLInputElement, CustomLabelInputProps>(
  ({ children, size, ...props }, ref) => {
    return (
      <>
        <Stack
          direction="row"
          alignItems="center"
          overflow="hidden"
          sx={(theme) => ({
            pointerEvents: 'none', // for retaining focus on <Select /> after closing menu
            paddingInlineStart: 1.5,
            ...theme.typography[size === 'small' ? 'body2' : 'body1'],
            color: 'inherit',
            bgcolor: 'inherit',
          })}
        >
          {children}
        </Stack>
        <input ref={ref} {...props} />
      </>
    );
  },
);
CustomLabelInput.displayName = 'CustomLabelInput';
