import { Box, ListItem, Menu, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavLink, generatePath, useLocation } from 'react-router-dom';

import { useCustomObjectListInfiniteQuery } from '@/api/customObject';
import Icon from '@/components/Icon';
import InfiniteScroll from '@/components/InfiniteScroll';
import { NavMenuItemToolTip, getNavMenuItemStyles } from '@/components/Navbar';
import { ROUTES } from '@/constants/navigation';
import { useMenuAnchor } from '@/hooks/useMenuAnchor';
import { useRouteWithLocale } from '@/hooks/useRouteWithLocale/useRouteWithLocale';

import { OBJECT_LOADING_LIMIT } from '../Settings/SettingsCustomObject/CustomObjectListTable';
import { fromApiGetCustomObject } from '../Settings/SettingsCustomObject/adapters';
import { CustomObject } from '../Settings/SettingsCustomObject/type';

export default function CustomObjectDataNav() {
  const location = useLocation();
  const routeTo = useRouteWithLocale();
  const { t } = useTranslation();

  const { anchorEl, open, handleAnchorClick, handleAnchorClose } =
    useMenuAnchor();
  const customObjectList = useCustomObjectListInfiniteQuery<CustomObject>({
    limit: OBJECT_LOADING_LIMIT,
    select: (data) => {
      return {
        ...data,
        pages: data.pages.flatMap((page) =>
          page.schemas.map((schema) => fromApiGetCustomObject(schema)),
        ),
      };
    },
    filter: {
      field: 'is_enabled',
      operator: '=',
      value: true,
    },
  });

  // if no custom object hide it from the navbar
  if (!customObjectList.data || !customObjectList.data.pages.length) {
    return null;
  }

  if (customObjectList.data?.pages.length === 1) {
    const customObject = customObjectList.data?.pages[0];
    return (
      <NavMenuItemToolTip
        title={customObject.name}
        placement="right"
        enterDelay={100}
        enterNextDelay={100}
      >
        <NavLink
          style={{ textDecoration: 'none' }}
          to={routeTo(
            generatePath(ROUTES.customObjectData, {
              id: customObject.id,
            }),
          )}
        >
          {({ isActive }) => (
            <ListItem
              component="span"
              disablePadding
              sx={(theme) => getNavMenuItemStyles(theme, isActive)}
            >
              <Icon icon="database" size={20} sx={{ flexShrink: 0 }} />
            </ListItem>
          )}
        </NavLink>
      </NavMenuItemToolTip>
    );
  }

  return (
    <>
      <NavMenuItemToolTip
        title={t('nav.custom-object-data', {
          defaultValue: 'Custom Objects',
        })}
        placement="right"
        enterDelay={100}
        enterNextDelay={100}
      >
        <ListItem
          component="span"
          disablePadding
          sx={(theme) => ({
            ...getNavMenuItemStyles(
              theme,
              location.pathname.includes('custom-objects-data'),
            ),
            cursor: 'pointer',
          })}
          onClick={handleAnchorClick}
        >
          <Icon icon="database" size={20} sx={{ flexShrink: 0 }} />
        </ListItem>
      </NavMenuItemToolTip>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleAnchorClose}
        elevation={0}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transitionDuration={250}
        disableAutoFocusItem={true}
        sx={{
          '& .MuiMenu-paper': {
            backgroundColor: 'darkBlue.90',
            borderRadius: '8px',
            minWidth: '260px',
          },
        }}
      >
        <Typography
          variant="subtitle"
          sx={(theme) => ({
            display: 'block',
            color: theme.palette.white,
            textTransform: 'uppercase',
            margin: theme.spacing(1, 2),
          })}
        >
          {t('nav.custom-object-data', {
            defaultValue: 'Custom Objects',
          })}
        </Typography>
        <InfiniteScroll
          loading={customObjectList.isFetchingNextPage}
          hasNext={customObjectList.hasNextPage}
          onNext={customObjectList.fetchNextPage}
        >
          <Box maxHeight="365px">
            {customObjectList.data.pages.map((item) => {
              return (
                <NavLink
                  to={routeTo(
                    generatePath(ROUTES.customObjectData, {
                      id: item.id,
                    }),
                  )}
                  style={{ textDecoration: 'none' }}
                  key={item.id}
                >
                  {({ isActive }) => (
                    <ListItem
                      component="span"
                      disablePadding
                      sx={(theme) => ({
                        ...getNavMenuItemStyles(theme, isActive),
                        padding: theme.spacing(1.5, 2),
                        borderRadius: 'none',
                      })}
                      onClick={handleAnchorClose}
                    >
                      <Typography
                        variant="body1"
                        sx={(theme) => ({
                          marginLeft: theme.spacing(1),
                          color: theme.palette.white,
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: '200px',
                        })}
                      >
                        {item.name}
                      </Typography>
                    </ListItem>
                  )}
                </NavLink>
              );
            })}
          </Box>
        </InfiniteScroll>
      </Menu>
    </>
  );
}
