import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import AccessDeniedErrorElement from '@/components/AccessDeniedErrorElement';
import { Button } from '@/components/Button';
import { ROUTES } from '@/constants/navigation';
import { useRouteWithLocale } from '@/hooks/useRouteWithLocale/useRouteWithLocale';

import PageLayoutSlot from '../../../components/PageLayoutSlot';
import PageTitle from '../../../components/PageTitle';
import { useSettingAccessRuleGuard } from '../hooks/useSettingAccessRuleGuard';
import { useSettingsLayout } from '../shared/SettingsLayout';
import DeletedContactsTable from './DeletedContactsTable';

export default function SettingsDeletedContacts() {
  const { pageTitleEl } = useSettingsLayout();
  const { t } = useTranslation();
  const { isDeletedContactAllowToView } = useSettingAccessRuleGuard();
  const routeTo = useRouteWithLocale();

  if (!isDeletedContactAllowToView()) {
    return (
      <AccessDeniedErrorElement
        mode="component"
        actions={
          <Button
            to={routeTo(ROUTES.settings)}
            component={Link}
            variant="contained"
          >
            {t('settings.error.access-denied.back-button', {
              defaultValue: 'Back to Settings',
            })}
          </Button>
        }
      />
    );
  }

  return (
    <Stack sx={{ p: '4px 0' }}>
      <PageLayoutSlot anchorEl={pageTitleEl}>
        <PageTitle
          title={t('settings.menu.contacts-and-data.deleted-contact', {
            defaultValue: 'Recently deleted contacts',
          })}
          subtitleComponent={
            <Typography variant="subtitle" sx={{ textTransform: 'uppercase' }}>
              {t('settings.menu.contacts-and-data.title')}
            </Typography>
          }
        />
      </PageLayoutSlot>
      <Stack spacing={3}>
        <Typography variant="body1">
          {t('deleted-contact.brief', {
            defaultValue:
              'Contacts will be permanently deleted and unable to be restored after 14 days since their initial deletion have passed',
          })}
        </Typography>
        <DeletedContactsTable />
      </Stack>
    </Stack>
  );
}
