import { Skeleton, TableCell, useTheme } from '@mui/material';

import TableLoadingSkeleton from '@/components/TableLoadingSkeleton';

const ContactsTableSkeleton = () => {
  const {
    table: { columnSizes },
  } = useTheme();
  return (
    <TableLoadingSkeleton
      withCheckbox
      columns={30}
      columnWidth="md"
      renderColumns={(columnsArray, columnWidth) => {
        return columnsArray.map((header) => {
          if (header === 0) {
            return (
              <TableCell
                key={header}
                sx={{ width: 'auto', minWidth: columnSizes.xl }}
              >
                <Skeleton
                  sx={{ backgroundColor: 'gray.30' }}
                  height={16}
                  variant="rectangular"
                />
              </TableCell>
            );
          }
          return (
            <TableCell key={header} sx={{ width: columnWidth }}>
              <Skeleton
                sx={{ backgroundColor: 'gray.30' }}
                height={16}
                variant="rectangular"
              />
            </TableCell>
          );
        });
      }}
      rows={15}
    />
  );
};

export default ContactsTableSkeleton;
