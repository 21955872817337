import {
  AuditHubApi,
  TravisBackendConversationDomainViewModelsRemarkResponse,
} from '@sleekflow/sleekflow-core-typescript-rxjs-apis';
import { inject, injectable } from 'inversify';
import { filter, map, take, retry, shareReplay } from 'rxjs';

import { ClassicRealTimeService } from '../signal-r/classic-real-time.service';
import { GetUserProfileAuditLogsParams } from './models/get-user-profile-audit-logs-params';
import { UserProfileAuditLogWrapper } from './wrappers/user-profile-audit-log-wrapper';

@injectable()
export class UserProfileAuditLogService {
  constructor(
    @inject(AuditHubApi) private auditHubApi: AuditHubApi,
    @inject(ClassicRealTimeService)
    private classicRealTimeService: ClassicRealTimeService,
  ) {}

  public getUserProfileAuditLogs$(
    getUserProfileAuditLogsParam: GetUserProfileAuditLogsParams,
    continuationToken: string | null,
  ) {
    return this.auditHubApi
      .auditHubAuditLogsGetUserProfileAuditLogsV2Post({
        travisBackendControllersSleekflowControllersAuditHubControllerGetUserProfileAuditLogsV2Request:
          {
            sleekflow_user_profile_id:
              getUserProfileAuditLogsParam.userProfileId,
            filters: getUserProfileAuditLogsParam.filters,
            continuation_token: continuationToken,
            limit: 50,
          },
      })
      .pipe(
        map((resp) => {
          return resp.data!.user_profile_audit_logs!.map((l) => {
            return new UserProfileAuditLogWrapper(l);
          });
        }),
      );
  }

  public getOngoingUpdatedUserProfileAuditLogs$(
    getUserProfileAuditLogsParam: GetUserProfileAuditLogsParams,
  ) {
    return this.classicRealTimeService.getOnRemarksReceived$().pipe(
      map((r: TravisBackendConversationDomainViewModelsRemarkResponse) => {
        return new UserProfileAuditLogWrapper({
          id: r.remarkId,
          sleekflow_company_id: '',
          sleekflow_user_profile_id: r.userProfileId,
          sleekflow_staff_id: r.remarksStaff?.userInfo?.id,
          type: r.type,
          audit_log_text: r.remarks,
          data: r.data,
          created_time: r.createdAt,
        });
      }),
      filter((r: UserProfileAuditLogWrapper) => {
        return (
          r.getSleekflowUserProfileIdSnapshot() ===
          getUserProfileAuditLogsParam.userProfileId
        );
      }),
    );
  }

  public createStaffManualAddedLog(
    sleekflowUserProfileId: string,
    auditLogText: string,
  ) {
    return this.auditHubApi
      .auditHubAuditLogsCreateStaffManualAddedLogPost({
        travisBackendControllersSleekflowControllersAuditHubControllerCreateStaffManualAddedLogRequest:
          {
            sleekflow_user_profile_id: sleekflowUserProfileId,
            audit_log_text: auditLogText,
          },
      })
      .pipe(
        take(1),
        retry(3),
        shareReplay({
          bufferSize: 1,
          refCount: false,
        }),
      );
  }
}
