import { TableCell, TableRow, Typography, useTheme } from '@mui/material';
import { Cell, flexRender } from '@tanstack/react-table';
import { MutableRefObject } from 'react';

import Icon from '@/components/Icon';

interface DraggingRowProps<T> {
  cells: Cell<T, unknown>[];
  headerRefs: MutableRefObject<HTMLTableCellElement[]>;
}

export function DraggingRow<T>({ cells, headerRefs }: DraggingRowProps<T>) {
  const theme = useTheme();
  const style = {
    boxShadow: theme.shadows[4],
    backgroundColor: 'white',
    py: '24px',
    px: '16px',
  };

  return (
    <TableRow style={style}>
      <TableCell
        width="40px"
        sx={{
          cursor: 'grabbing',
        }}
      >
        <Icon
          icon="drag-and-drop"
          sx={{
            color: 'gray.90',
          }}
          size={16}
        />
      </TableCell>

      {cells.map((cell, idx) => {
        return (
          <TableCell
            key={cell.id}
            width={headerRefs.current?.[idx].offsetWidth}
          >
            <Typography
              color="darkBlue.90"
              variant="body2"
              sx={(theme) => ({
                ...theme.typography.ellipsis,
                maxWidth: `${cell.column.getSize()}px`,
              })}
            >
              {flexRender(cell.column.columnDef.cell, {
                ...cell.getContext(),
              })}
            </Typography>
          </TableCell>
        );
      })}
    </TableRow>
  );
}
