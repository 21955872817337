/* eslint-disable @typescript-eslint/no-empty-function */
import { LicenseInfo } from '@mui/x-license';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import mixpanel from 'mixpanel-browser';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import AppRoutes from '@/AppRoutes';

import './i18n';

LicenseInfo.setLicenseKey(
  '2628a354dee015fdf83989f6f390ed97Tz04NjE3NCxFPTE3NDE3Njk5NzgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
);

// Disable console logs on production
if (import.meta.env.VITE_USER_NODE_ENV === 'production') {
  console.log = () => {};
  console.debug = () => {};
  console.info = () => {};
  console.warn = () => {};
  // Don't override in order to capture the error from console then send to sentry
  // console.error = () => {};
}

LicenseInfo.setLicenseKey(
  '2628a354dee015fdf83989f6f390ed97Tz04NjE3NCxFPTE3NDE3Njk5NzgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
);

Sentry.init({
  environment: import.meta.env.VITE_SENTRY_ENV,
  dsn: 'https://465f4bc1cd4e491ebf7fa3a6c5ff3838@o983280.ingest.sentry.io/6647583',
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new CaptureConsoleIntegration({
      levels: ['error'],
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  sampleRate: import.meta.env.VITE_SENTRY_SAMPLE_RATE,
  tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,
  tracePropagationTargets: [],
  allowUrls: [
    'v1.sleekflow.io',
    'v1-dev.sleekflow.io',
    'v1-staging.sleekflow.io',
    'app.sleekflow.io',
    'beta.sleekflow.io',
    'staging-revamp.sleekflow.io',
    'uat-revamp.sleekflow.io',
    'dev-revamp.sleekflow.io',
  ],
});

mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
  debug: false,
  persistence: 'localStorage',
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <AppRoutes />
  </React.StrictMode>,
);
