import {
  CustomObjectSchema,
  FilterParam,
  SchemaPropertyDataType,
  UpdatedCustomObject,
} from '@/api/customObject';

import {
  CreateCustomObjectForm,
  CustomObject,
  CustomObjectPropertyType,
} from './type';

export function fromApiGetCustomObject(
  schema: CustomObjectSchema,
): CustomObject {
  return {
    name: schema.display_name,
    id: schema.id,
    displayedId: schema.unique_name,
    relationship: schema.relationship_type,
    isEnabled: schema.is_enabled,
    updatedAt: schema.updated_at,
    primaryProperty: {
      id: schema.primary_property.id,
      displayedId: schema.primary_property.unique_name,
      name: schema.primary_property.display_name,
      type: fromApiCustomObjectPropertyType(
        schema.primary_property.data_type.name,
      ),
      isSearchable: schema.primary_property.is_searchable,
      isPinned: schema.primary_property.is_pinned,
      isVisible: schema.primary_property.is_visible,
      createdAt: schema.primary_property.created_at,
      ...(schema.primary_property.created_by && {
        createdBy: {
          staffId: schema.primary_property.created_by.sleekflow_staff_id,
          teamIds: schema.primary_property.created_by.sleekflow_staff_team_ids,
        },
      }),
      isIdAutoGenerated:
        schema.primary_property.primary_property_config.is_auto_generated,
      ...(schema.primary_property.primary_property_config.sequential && {
        displayedIdPrefixConfig: {
          prefix:
            schema.primary_property.primary_property_config.sequential
              .sequence_prefix,
          digitLength:
            schema.primary_property.primary_property_config.sequential
              .sequence_digit_length,
        },
      }),
    },
    properties: schema.properties
      .sort((a, b) => a.display_order - b.display_order)
      .map((property) => ({
        id: property.id,
        displayedId: property.unique_name,
        name: property.display_name,
        type: fromApiCustomObjectPropertyType(property.data_type.name),
        isRequired: property.is_required,
        isSearchable: property.is_searchable,
        isPinned: property.is_pinned,
        isVisible: property.is_visible,
        ...(property.created_by && {
          createdBy: {
            staffId: property.created_by.sleekflow_staff_id,
            teamIds: property.created_by.sleekflow_staff_team_ids,
          },
        }),
        createdAt: property.created_at,
        ...(property.options && {
          options: property.options
            .sort((a, b) => a.display_order - b.display_order)
            .map((option) => ({
              id: option.id,
              value: option.value,
            })),
        }),
      })),
  };
}

export function toApiUpdateCustomObject(
  customObject: CustomObject,
): UpdatedCustomObject {
  return {
    id: customObject.id,
    display_name: customObject.name,
    primary_property_display_name: customObject.primaryProperty.name,
    is_enabled: customObject.isEnabled,
    properties: customObject.properties.map((property, index) => ({
      id: property.id,
      display_name: property.name,
      unique_name: property.displayedId,
      data_type: { name: toApiSchemaPropertyType(property.type) },
      is_required: property.isRequired,
      is_visible: property.isVisible,
      is_pinned: property.isPinned,
      is_searchable: property.isSearchable,
      created_at: property.createdAt,
      display_order: index + 1,
      ...(property.createdBy && {
        created_by: {
          sleekflow_staff_id: property.createdBy.staffId,
          sleekflow_staff_team_ids: property.createdBy.teamIds,
        },
      }),
      ...(property.options && {
        options: property.options.map((option, index) => ({
          id: option.id,
          value: option.value,
          display_order: index,
        })),
      }),
    })),
  };
}

export function fromApiCustomObjectPropertyType(
  type: SchemaPropertyDataType,
): CustomObjectPropertyType {
  if (type === 'numeric') return 'number';
  if (type === 'decimal') return 'decimalNumber';
  if (type === 'multiple_choice') return 'multiSelection';
  if (type === 'single_choice') return 'singleSelection';
  if (type === 'single_line_text') return 'text';
  if (type === 'datetime') return 'dateTime';

  return type;
}

function toApiSchemaPropertyType(
  type: CustomObjectPropertyType,
): SchemaPropertyDataType {
  if (type === 'number') return 'numeric';
  if (type === 'decimalNumber') return 'decimal';
  if (type === 'multiSelection') return 'multiple_choice';
  if (type === 'singleSelection') return 'single_choice';
  if (type === 'text') return 'single_line_text';
  if (type === 'dateTime') return 'datetime';

  return type;
}

export function toApiCreateCustomObject(data: CreateCustomObjectForm) {
  const isPrimaryPropertyIdAutoGen = Boolean(
    data.primaryProperty.isIdAutoGenerated,
  );
  return {
    display_name: data.name,
    unique_name: data.displayedId,
    relationship_type: data.relationship,
    primary_property_input: {
      display_name: data.primaryProperty.name,
      unique_name: data.primaryProperty.displayedId,
      data_type: {
        name: toApiSchemaPropertyType(data.primaryProperty.type),
      },
      is_visible: true,
      is_pinned: true,
      is_searchable: true,
      primary_property_config: {
        is_auto_generated: isPrimaryPropertyIdAutoGen,
        sequential: isPrimaryPropertyIdAutoGen
          ? {
              sequence_prefix:
                data.primaryProperty.displayedIdPrefixConfig.prefix.toUpperCase(),
              sequence_digit_length: 10,
            }
          : null,
      },
    },
    property_inputs: data.properties
      ? data.properties.map((property, idx) => ({
          display_name: property.name,
          unique_name: property.displayedId,
          data_type: {
            name: toApiSchemaPropertyType(property.type),
          },
          is_required: property.isRequired,
          is_visible: true,
          is_pinned: true,
          is_searchable: true,
          display_order: idx,
          ...(property.options && {
            options: property.options.map((option, index) => ({
              value: option.value,
              display_order: index,
            })),
          }),
        }))
      : [],
  };
}

export function toApiFilterCustomObjectListPayload({
  filtersWithAndLogic,
  filtersWithOrLogic,
  limit,
}: {
  limit: number;
  filtersWithAndLogic?: FilterParam[];
  filtersWithOrLogic?: FilterParam[];
}) {
  return {
    limit,
    continuation_token: null,
    filter_groups: (filtersWithAndLogic
      ? filtersWithAndLogic.map((filter) => ({
          filters: [
            {
              field_name: filter.field,
              operator: filter.operator,
              value: filter.value,
            },
          ],
        }))
      : []
    ).concat(
      filtersWithOrLogic
        ? [
            {
              filters: filtersWithOrLogic.map((filter) => ({
                field_name: filter.field,
                operator: filter.operator,
                value: filter.value,
              })),
            },
          ]
        : [],
    ),
    sorts: [
      {
        field_name: 'sorting_weight',
        direction: 'asc',
      },
    ],
  };
}
