import { useTranslation } from 'react-i18next';

import { useGetIsFeatureFlagsEnabledQuery } from '@/api/common';
import { ROUTES } from '@/constants/navigation';
import { generateV1RedirectionLink } from '@/utils/v1-utils';

import { useRouteWithLocale } from './useRouteWithLocale/useRouteWithLocale';

export function useSubscriptionRedirectRoute() {
  const { data: isFeatureFlagsEnabled } = useGetIsFeatureFlagsEnabledQuery();
  const {
    i18n: { language },
  } = useTranslation();
  const routeTo = useRouteWithLocale();
  const V1_SUBSCRIPTION_PLAN_URL = generateV1RedirectionLink({
    language,
    path: '/settings/plansubscription',
  });
  if (isFeatureFlagsEnabled?.isGlobalPricingFeatureEnabled) {
    return routeTo(ROUTES.settingsSubscriptions).toString();
  }
  return V1_SUBSCRIPTION_PLAN_URL;
}
