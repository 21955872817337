import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import {
  useSafeDeletedUserProfilesIdsQuery,
  useSafeDeletedUserProfilesQuery,
} from '@/api/userProfile';
import { getFullName } from '@/utils/messaging';
import { getTablePaginationPageNumber, TABLE_PAGE_SIZE } from '@/utils/table';

import { ColumnsType } from './DeletedContactsTable';
import { SEARCH_PARAMS } from './constant';

export type DeletedContacts = {
  userProfileId: string;
  name: string;
  email: string;
  phoneNumber: string;
  deletedAt: string;
  deletedBy?: {
    name: string;
    id: number;
    profileUrl?: string;
  };
  scheduledHardDeleteAt: string;
};

export default function useDeletedContactsTable({
  columns,
}: {
  columns: ColumnsType;
}) {
  const [rowSelection, setRowSelection] = useState({});
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const page = getTablePaginationPageNumber(
    searchParams.get(SEARCH_PARAMS.page),
  );
  const orderBy = searchParams.get(SEARCH_PARAMS.orderBy);
  const direction = searchParams.get(SEARCH_PARAMS.direction);

  const {
    data: safeDeletedUserProfiles,
    isFetching,
    isLoading,
  } = useSafeDeletedUserProfilesQuery<{
    contacts: DeletedContacts[];
    total: number;
  }>({
    params: {
      limit: TABLE_PAGE_SIZE,
      offset: page ? (page - 1) * TABLE_PAGE_SIZE : 0,
      direction: direction ? toApiDirection(direction) : undefined,
      orderBy: orderBy ? toApiOrderBy(orderBy) : undefined,
    },
    select: (data) => ({
      contacts: data.safeDeletedUserProfiles.map((p) => ({
        userProfileId: p.userProfile.id,
        name: getFullName({
          firstName: p.userProfile.firstName,
          lastName: p.userProfile.lastName,
          fallback:
            p.userProfile.phoneNumber ||
            p.userProfile.email ||
            t('general.unknown-label'),
        }),
        email: p.userProfile.email,
        phoneNumber: p.userProfile.phoneNumber,
        deletedAt: p.deletedAt,
        deletedBy: p.deletedByStaff
          ? {
              name:
                p.deletedByStaff.userInfo.displayName?.trim() ||
                getFullName({
                  firstName: p.deletedByStaff.userInfo.firstName,
                  lastName: p.deletedByStaff.userInfo.lastName,
                  fallback:
                    p.deletedByStaff.userInfo.email ||
                    t('general.unknown-label'),
                }),
              id: p.deletedByStaff.staffId,
              profileUrl: p.deletedByStaff.profilePictureURL,
            }
          : undefined,
        scheduledHardDeleteAt: p.scheduledHardDeleteAt,
      })),
      total: data.totalResult,
    }),
    keepPreviousData: true,
  });
  const { data: totalDeletedContactIds } = useSafeDeletedUserProfilesIdsQuery();

  const table = useReactTable({
    data: safeDeletedUserProfiles?.contacts ?? [],
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: true,
    state: {
      pagination: {
        pageIndex: page - 1 || 0,
        pageSize: TABLE_PAGE_SIZE,
      },
      rowSelection,
    },
    onRowSelectionChange: setRowSelection,
    manualPagination: true,
    pageCount: safeDeletedUserProfiles?.total
      ? Math.ceil(safeDeletedUserProfiles.total / TABLE_PAGE_SIZE)
      : 0,
    getRowId: (row) => row.userProfileId,
  });

  return {
    ...table,
    isFetching,
    isLoading,
    totalDeletedContacts: safeDeletedUserProfiles?.total ?? 0,
    currenPage: page,
    totalDeletedContactIds: totalDeletedContactIds ?? [],
  };
}

function toApiOrderBy(orderBy: string) {
  switch (orderBy) {
    case 'name':
      return 'FullName';
    case 'deletedOn':
      return 'DeletedAt';
    case 'deletedBy':
      return 'DeletedBy';
    case 'daysRemaining':
      return 'ScheduledHardDeleteAt';
  }
}

function toApiDirection(direction: string) {
  switch (direction) {
    case 'desc':
      return 'DESC';
    case 'asc':
      return 'ASC';
  }
}
