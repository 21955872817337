import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { TFunction } from 'react-i18next';
import {
  AnyObject,
  array,
  date,
  mixed,
  number,
  object,
  string,
  TestContext,
} from 'yup';

import { axiosClient } from '@/api/axiosClient';
import {
  CustomObjectDataFromApi,
  SchemaRelationshipType,
} from '@/api/customObject';
import { RoleType } from '@/api/types';
import { isUniqueIdValid } from '@/pages/Settings/SettingsCustomObject/shared/utils';
import {
  CustomObject,
  CustomObjectPropertyOption,
} from '@/pages/Settings/SettingsCustomObject/type';

import { CustomObjectData, CustomObjectDataProperty } from '../type';

type CustomObjectDataFormProperty = {
  id: string;
  type: string;
  isRequired: boolean;
  options?: CustomObjectPropertyOption[];
};

export const EMPTY_OPTION_VALUE = '-';

export function getCreateDataFormValidationSchema(
  t: TFunction,
  customObject: CustomObject,
) {
  return yupResolver(
    object({
      primaryPropertyValue: string()
        .trim()
        .test('primary-property-required', (value, ctx) => {
          if (customObject.primaryProperty.isIdAutoGenerated) return true;
          if (!value)
            return ctx.createError({
              message: t('general.required-field-validation'),
            });
          return isUniqueIdValid(value)
            ? true
            : ctx.createError({
                message: t('settings.custom-object.form-error.invalid-id', {
                  defaultValue:
                    'You can only use lowercase characters, numbers and underscores “_”',
                }),
              });
        })
        .test(
          'primary-property-uniqueness',
          t('custom-object-data.primary-property-not-unique', {
            defaultValue:
              'The value you entered already exists. Please rename and try again.',
          }),
          async (value) => {
            try {
              const response = await axiosClient.post(
                '/CustomObject/SchemafulObjects/GetSchemafulObjectsCount',
                {
                  schema_id: customObject.id,
                  filter_groups: [
                    {
                      filters: [
                        {
                          field_name: 'primary_property_value',
                          operator: '=',
                          value: value,
                          is_property_value: false,
                        },
                      ],
                    },
                  ],
                },
              );
              return response.data?.count === 0;
            } catch (_e) {
              return false;
            }
          },
        ),
      properties: object().shape(
        getPropertiesValidationRules(
          customObject.properties.filter(
            (property) => property.isRequired,
          ) as unknown as CustomObjectDataProperty[],
          t,
        ),
      ),
      contact: object()
        .shape({
          id: string(),
        })
        .test('valid-contact', async (value, ctx) =>
          validateContactData(value, ctx, t, {
            relationship: customObject.relationship,
            id: customObject.id,
          }),
        ),
    }),
  );
}

export function getEditDataFormValidationSchema(
  t: TFunction,
  customObjectData: CustomObjectData,
) {
  return yupResolver(
    object({
      properties: object().shape(
        getPropertiesValidationRules(
          customObjectData.properties.filter(
            (property) => property.isRequired,
          ) as unknown as CustomObjectDataProperty[],
          t,
        ),
      ),
      contact: object()
        .shape({
          id: string(),
        })
        .test('valid-contact', async (value, ctx) =>
          validateContactData(value, ctx, t, {
            relationship: customObjectData.relationship,
            id: customObjectData.customObjectId,
          }),
        ),
    }),
  );
}

function getPropertiesValidationRules(
  properties: CustomObjectDataFormProperty[],
  t: TFunction,
) {
  return properties.reduce((acc, property) => {
    switch (property.type) {
      case 'text':
        acc[property.id] = string()
          .trim()
          .min(1, t('general.required-field-validation'))
          .required(t('general.required-field-validation'));
        break;
      case 'number':
      case 'decimalNumber':
        acc[property.id] = number()
          .required(t('general.required-field-validation'))
          .typeError(
            t('custom-object-data.create-data.input.invalid-format-number', {
              defaultValue: 'value must be a number',
            }),
          );
        break;
      case 'date':
      case 'dateTime':
        acc[property.id] = date()
          .typeError(t('general.required-field-validation'))
          .required(t('general.required-field-validation'));
        break;
      case 'multiSelection':
        acc[property.id] = array()
          .min(1, t('general.required-field-validation'))
          .required(t('general.required-field-validation'));
        break;
      case 'singleSelection':
        acc[property.id] = string()
          .oneOf(
            property.options?.map((o) => o.id) || [],
            t('general.required-field-validation'),
          )
          .trim()
          .min(1, t('general.required-field-validation'))
          .required(t('general.required-field-validation'));
        break;
      case 'boolean':
        acc[property.id] = string()
          .oneOf(['true', 'false'], t('general.required-field-validation'))
          .required(t('general.required-field-validation'));
        break;
      default:
        acc[property.id] = mixed().required(
          t('general.required-field-validation'),
        );
    }
    return acc;
  }, {} as { [key: string]: any });
}

export function getPropertyDefaultValue(
  property: CustomObjectDataFormProperty,
) {
  if (property.type === 'multiSelection') return [];
  return '';
}

export function getRequiredProperties(
  properties: CustomObjectDataFormProperty[],
) {
  return properties.filter((property) => property.isRequired);
}

export function getEditCustomObjectDataDefaultValue(
  customObjectData: CustomObjectData,
) {
  return {
    contact: {
      id: customObjectData.contact?.id || '',
      fullName: customObjectData.contact?.name || '',
    },
    properties: customObjectData.properties.reduce<Record<any, any>>(
      (acc, property) => {
        if (property.type === 'multiSelection') {
          acc[property.id] = property.value || [];
        } else {
          acc[property.id] = property.value;
        }

        return acc;
      },
      {},
    ),
  };
}

export function getTableColumnHeaderNames(data?: CustomObjectData) {
  if (!data) return { primaryProperty: '', properties: [] };
  return {
    primaryProperty: data.primaryProperty.name,
    properties: data.properties.map((property) => property?.name),
  };
}

export function formatDate(date: string, type: 'date' | 'dateTime'): string {
  if (!dayjs(date).isValid()) {
    return date;
  }

  return type === 'date'
    ? dayjs(date).format('DD MMM YYYY')
    : dayjs(date).format('DD MMM YYYY HH:mm');
}

export async function validateContactData(
  value: { id?: string },
  ctx: TestContext<AnyObject>,
  t: TFunction,
  {
    relationship,
    id,
  }: {
    relationship: SchemaRelationshipType;
    id: string;
  },
) {
  if (!value?.id?.length)
    return ctx.createError({
      message: t('general.required-field-validation'),
    });
  if (relationship !== 'one-to-one') return true;
  try {
    const response = await axiosClient.post(
      '/CustomObject/SchemafulObjects/GetSchemafulObjectsCount',
      {
        schema_id: id,
        filter_groups: [
          {
            filters: [
              {
                field_name: 'sleekflow_user_profile_id',
                operator: '=',
                value: value.id,
                is_property_value: false,
              },
            ],
          },
        ],
      },
    );

    return response.data?.count === 0
      ? true
      : ctx.createError({
          message: t('custom-object-data.contact-one-to-one-error', {
            defaultValue:
              'You cannot create new records because this object is set as a one-to-one relationship',
          }),
        });
  } catch (_e) {
    return false;
  }
}

export function generateDateRange(
  startDate: string | null,
  endDate: string | null,
): Date[] | undefined {
  if (!startDate || !endDate) return undefined;
  const start = dayjs(startDate);
  const end = dayjs(endDate);
  const diffInDays = end.diff(start, 'day');

  if (diffInDays < 0) return undefined;

  const dateArray = [];
  for (let i = 0; i <= diffInDays; i++) {
    const date = new Date(start.add(i, 'day').toDate());
    dateArray.push(date);
  }
  return dateArray;
}

export function isDataDeletable(
  data: CustomObjectDataFromApi,
  myProfile?: {
    roleType: RoleType;
    associatedTeamsId: string[];
    staffId: string;
  },
) {
  // checking if created_by field is not null or undefined
  const isDataHasCreateBy = data.created_by != null;
  if (myProfile === undefined) return false;

  if (
    isDataHasCreateBy &&
    data.created_by?.sleekflow_staff_id === String(myProfile.staffId)
  ) {
    return true;
  }
  if (myProfile.roleType === 'Admin') {
    return true;
  }
  if (myProfile.roleType === 'TeamAdmin' && isDataHasCreateBy) {
    return !!data.created_by?.sleekflow_staff_team_ids?.find((teamId) =>
      myProfile.associatedTeamsId.includes(teamId),
    );
  }
  return false;
}
