import { parseISO } from 'date-fns';
import { interfaces } from 'inversify';
import { distinctUntilChanged, Observable, takeUntil } from 'rxjs';

import { RxjsUtils } from '@/services/rxjs-utils/rxjs-utils';

import { ArrayPagedDataSource } from '../data-sources/array-paged-data-source';
import { DataSourceListRange } from '../data-sources/models/data-source-list-range';
import { GetUserProfileAuditLogsParams } from './models/get-user-profile-audit-logs-params';
import { UserProfileAuditLogService } from './user-profile-audit-log.service';
import { UserProfileAuditLogWrapper } from './wrappers/user-profile-audit-log-wrapper';

export class UserProfileAuditLogDataSource extends ArrayPagedDataSource<UserProfileAuditLogWrapper> {
  private readonly userProfileAuditLogService: UserProfileAuditLogService;
  private readonly pageSize = 20;

  private nextContinuationToken?: string | null;
  private fetchedContinuationTokens = new Set<string>();

  private hasSetup = false;

  public constructor(container: interfaces.Container) {
    super();

    this.userProfileAuditLogService = container.get<UserProfileAuditLogService>(
      UserProfileAuditLogService,
    );
  }

  public setupAndGet$(
    getUserProfileAuditLogsParams: GetUserProfileAuditLogsParams,
    listRange$: Observable<DataSourceListRange>,
  ): Observable<UserProfileAuditLogWrapper[]> {
    listRange$
      .pipe(
        distinctUntilChanged((a, b) => {
          return a.start == b.start && a.end == b.end;
        }),
        takeUntil(this.getComplete$()),
        takeUntil(this.getDisconnect$()),
      )
      .subscribe((range) => {
        if (range.end > this.getCachedItems().length - 1) {
          this.fetchNextPage(getUserProfileAuditLogsParams);
        }
      });

    if (this.hasSetup) {
      return this.getCachedItems$();
    }

    this.hasSetup = true;

    // Yields the initial empty array
    this.yieldSortedItems(true);

    this.setup(getUserProfileAuditLogsParams);

    return this.getCachedItems$();
  }

  private setup(
    getUserProfileAuditLogsParams: GetUserProfileAuditLogsParams,
  ): void {
    this.setupSortFunc(this.sortDescFunc);

    this.fetchNextPage(getUserProfileAuditLogsParams);

    this.userProfileAuditLogService
      .getOngoingUpdatedUserProfileAuditLogs$(getUserProfileAuditLogsParams)
      .pipe(takeUntil(this.getDisconnect$()))
      .subscribe((x) => {
        console.log(
          'getOngoingUpdatedUserProfileAuditLogs$',
          getUserProfileAuditLogsParams,
          x,
        );
        this.addItem(x);
      });
  }

  private fetchNextPage(
    getUserProfileAuditLogsParams: GetUserProfileAuditLogsParams,
  ): void {
    // All data has been fetched
    if (this.nextContinuationToken === null) {
      return;
    }

    const nextContinuationToken =
      this.nextContinuationToken === undefined
        ? null
        : this.nextContinuationToken;

    if (this.nextContinuationToken !== undefined) {
      if (this.nextContinuationToken.includes(this.nextContinuationToken)) {
        return;
      }

      this.fetchedContinuationTokens.add(this.nextContinuationToken);
    }

    // Update isLoading to true before starting to fetch data
    this.setIsFetchingNextPage(true);

    this.userProfileAuditLogService
      .getUserProfileAuditLogs$(
        getUserProfileAuditLogsParams,
        nextContinuationToken,
      )
      .pipe(
        takeUntil(this.getComplete$()),
        takeUntil(this.getDisconnect$()),
        RxjsUtils.getRetryAPIRequest(),
      )
      .subscribe(
        (userProfileAuditLogWrappers) => {
          if (
            userProfileAuditLogWrappers &&
            userProfileAuditLogWrappers.length > 0
          ) {
            if (userProfileAuditLogWrappers.length < this.pageSize) {
              this.complete();
            }

            this.addItems(userProfileAuditLogWrappers);
          } else {
            this.yieldSortedItems();
          }
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.setIsFetchingNextPage(false);
        },
      );
  }

  private sortDescFunc = (
    a: UserProfileAuditLogWrapper,
    b: UserProfileAuditLogWrapper,
  ) => {
    return (
      parseISO(b.getCreatedTimeSnapshot()).getTime() -
      parseISO(a.getCreatedTimeSnapshot()).getTime()
    );
  };
}
