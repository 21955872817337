import {
  Box,
  Checkbox,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import {
  createColumnHelper,
  flexRender,
  RowSelectionState,
  TableState,
  Updater,
} from '@tanstack/react-table';
import dayjs from 'dayjs';
import { parsePhoneNumber } from 'libphonenumber-js/min';
import { useEffect, useMemo, useRef, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { useGlobalBanner } from '@/GlobalBanner';
import { useCompanyUsageQuery } from '@/api/company';
import { Avatar } from '@/components/Avatar';
import { Button } from '@/components/Button';
import EmptyResult from '@/components/EmptyResult';
import Icon, { IconProps } from '@/components/Icon';
import { ScrollArea } from '@/components/ScrollArea';
import { EMPTY_CELL_VALUE, TableColumnSizeOptions } from '@/constants/table';
import ContactsTableSkeleton from '@/pages/Contacts/shared/ContactsTable/ContactsTableSkeleton';
import TableFetchingOverlay from '@/pages/Contacts/shared/TableFetchingOverlay';
import { isCustomValidPhoneNumber } from '@/pages/Contacts/shared/utils';
import { TABLE_PAGE_SIZE } from '@/utils/table';

import HardDeleteContacts from './HardDeleteContact';
import {
  RestoreBulkDeletedContacts,
  DeletedContactRowActions,
} from './RestoreDeletedContacts';
import {
  SEARCH_PARAMS,
  SORT_DIRECTION_MAP,
  SORT_DIRECTION,
  SORT_DIRECTION_ICON,
  SORTABLE_COLUMNS,
  TABLE_COLUMNS,
} from './constant';
import useDeletedContactsTable, {
  DeletedContacts,
} from './useDeletedContactsTable';

export type ColumnsType = ReturnType<typeof getColumns>;

export default function DeletedContactsTable() {
  const { t } = useTranslation();
  const { data } = useCompanyUsageQuery();

  const {
    table: { columnSizes },
  } = useTheme();

  const viewportRef = useRef<HTMLDivElement>(null);
  const restorableContacts =
    (data?.maximumContacts ?? 0) - (data?.totalContacts ?? 0);
  const table = useDeletedContactsTable({
    columns: useMemo(
      () =>
        getColumns(
          t,
          columnSizes,
          restorableContacts,
          data?.maximumContacts ?? 0,
        ),
      [columnSizes, data?.maximumContacts, restorableContacts, t],
    ),
  });

  const tableHeaders = table.getHeaderGroups();
  const tableRows = table.getRowModel().rows;
  const selectedContacts = Object.keys(table.getState().rowSelection);
  const totalPagedContacts =
    (table.currenPage - 1) * TABLE_PAGE_SIZE + tableRows.length;

  const globalBannerHeight = useGlobalBanner((state) => state.totalHeight);

  if (
    table.totalDeletedContacts === 0 &&
    (!table.isFetching || !table.isLoading)
  ) {
    return (
      <Stack sx={{ p: '20px' }}>
        <EmptyResult
          title={t('deleted-contact.empty-title', {
            defaultValue: 'No contacts here',
          })}
          description={t('deleted-contact.empty-content', {
            defaultValue: 'You have not deleted any contacts recently',
          })}
        />
      </Stack>
    );
  }

  return (
    <Stack height={`calc(100svh - 108px - ${globalBannerHeight}px)`}>
      <ScrollArea
        slotProps={{
          viewport: {
            ref: viewportRef,
            style: {
              overflowX: table.isFetching ? 'hidden' : 'scroll',
              overflowY: table.isFetching ? 'hidden' : 'scroll',
            },
          },
        }}
        sx={{
          position: 'relative',
          width: '100%',
          height: 'auto',
          padding: '0px 0px 16px 0px',
        }}
      >
        {table.isLoading ? (
          <ContactsTableSkeleton />
        ) : (
          <Box sx={{ position: 'relative' }}>
            <TableFetchingOverlay
              isFetching={table.isFetching}
              containerRef={viewportRef}
            />
            <Table
              sx={{
                minWidth: '100%',
                width: 'max-content',
                maxHeight: '100px',
                borderCollapse: 'separate',
              }}
            >
              <TableHead sx={{ position: 'sticky', top: 0, zIndex: 50 }}>
                {tableHeaders.map((headerGroup) => {
                  return (
                    <TableRow key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                        const size = header.getSize();

                        if (header.id === 'name') {
                          return (
                            <TableCell
                              sx={{ width: 'auto', minWidth: `${size}px` }}
                              key={header.id}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext(),
                              )}
                            </TableCell>
                          );
                        }
                        return (
                          <TableCell
                            sx={{
                              width: `${size}px`,
                            }}
                            key={header.id}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
                {selectedContacts.length > 0 && (
                  <TableActionBar
                    selectedRows={selectedContacts}
                    setRowSelection={table.setRowSelection}
                    totalDeletedContactIds={table.totalDeletedContactIds}
                    restorableContacts={restorableContacts}
                    contactLimit={data?.maximumContacts ?? 0}
                  />
                )}
              </TableHead>
              <TableBody>
                {tableRows.map((row) => {
                  const isSelected = row.getIsSelected();
                  const visibleCells = row.getVisibleCells();

                  return (
                    <TableRow
                      key={row.id}
                      selected={isSelected}
                      onMouseEnter={() => {
                        table.setState((state) => ({
                          ...state,
                          hoveredRowId: row.id,
                        }));
                      }}
                      onMouseLeave={() => {
                        table.setState((state) => ({
                          ...state,
                          hoveredRowId: undefined,
                        }));
                      }}
                    >
                      {visibleCells.map((cell) => {
                        return (
                          <TableCell key={cell.id}>
                            {flexRender(cell.column.columnDef.cell, {
                              ...cell.getContext(),
                            })}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        )}
      </ScrollArea>
      {tableRows.length > 0 && (
        <DeletedContactsPagination
          totalPagedContacts={totalPagedContacts}
          totalContacts={table.totalDeletedContacts}
          page={table.getState().pagination.pageIndex + 1}
          pageCount={table.getPageCount()}
        />
      )}
    </Stack>
  );
}

function EllipsisTooltip({
  content,
  maxWidth,
}: {
  content: string;
  maxWidth: string;
}) {
  const textElementRef = useRef<HTMLElement | null>(null);

  const [textOverflown, setTextOverflown] = useState(false);

  useEffect(() => {
    const text = textElementRef.current!;
    setTextOverflown(text.scrollWidth > text.clientWidth);
  }, []);
  return (
    <Tooltip
      title={content}
      disableInteractive={!textOverflown}
      disableHoverListener={!textOverflown}
    >
      <Typography
        ref={textElementRef}
        color="darkBlue.90"
        variant="body2"
        sx={(theme: Theme) => ({
          ...theme.typography.ellipsis,
          maxWidth,
          color: 'inherit',
          fontWeight: 'inherit',
        })}
      >
        {content}
      </Typography>
    </Tooltip>
  );
}
const columnHelper = createColumnHelper<DeletedContacts>();
export function getColumns(
  t: TFunction,
  columnSizes: Record<TableColumnSizeOptions, number>,
  restorableContacts: number,
  contactLimit: number,
) {
  return [
    columnHelper.display({
      id: 'rowSelect',
      size: columnSizes.xxs,
      header: ({ table }) => {
        return (
          <Checkbox
            indeterminate={table.getIsSomePageRowsSelected()}
            checked={table.getIsAllRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
          />
        );
      },
      cell: ({ row }) => {
        return (
          <Checkbox
            sx={{
              '&.Mui-disabled > div': {
                backgroundColor: 'gray.20',
                borderColor: 'gray.20',
              },
            }}
            checked={row.getIsSelected()}
            onChange={row.getToggleSelectedHandler()}
          />
        );
      },
    }),
    columnHelper.accessor((row) => row.name, {
      id: TABLE_COLUMNS.name,
      size: columnSizes.lg,
      header: () => {
        return (
          <SortableHeaderCell fieldName="name">
            {t('deleted-contact.name', {
              defaultValue: 'Name',
            })}
          </SortableHeaderCell>
        );
      },
      cell: (info) => {
        const isHovering =
          (
            info.table.getState() as TableState & {
              hoveredRowId: string;
            }
          ).hoveredRowId === info.row.id;
        return (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <EllipsisTooltip
              content={info.getValue() ?? t('general.untitled-label')}
              maxWidth={`${info.column.getSize()}px`}
            />
            <Box
              sx={{
                visibility: isHovering ? 'visible' : 'hidden',
              }}
            >
              <DeletedContactRowActions
                contact={{
                  id: info.row.original.userProfileId,
                  name: info.getValue(),
                }}
                restorableContacts={restorableContacts}
                contactLimit={contactLimit}
              />
            </Box>
          </Stack>
        );
      },
    }),
    columnHelper.accessor((row) => row.email, {
      id: TABLE_COLUMNS.email,
      size: columnSizes.lg,
      header: () => {
        return t('deleted-contact.email', {
          defaultValue: 'Email',
        });
      },
      cell: (info) => {
        return (
          <EllipsisTooltip
            content={info.getValue() ?? EMPTY_CELL_VALUE}
            maxWidth={`${info.column.getSize()}px`}
          />
        );
      },
    }),
    columnHelper.accessor((row) => row.phoneNumber, {
      id: TABLE_COLUMNS.phoneNumber,
      size: columnSizes.lg,
      header: () => {
        return t('deleted-contact.phone-number', {
          defaultValue: 'Phone number',
        });
      },
      cell: (info) => {
        const value = info.getValue() as string | undefined;
        const transformedPhoneNumber = `+${value}`;
        try {
          if (value && isCustomValidPhoneNumber(transformedPhoneNumber)) {
            const phoneNumber = parsePhoneNumber(transformedPhoneNumber);
            return (
              <EllipsisTooltip
                content={phoneNumber.formatInternational() ?? EMPTY_CELL_VALUE}
                maxWidth={`${info.column.getSize()}px`}
              />
            );
          }
        } catch (e) {
          console.error(e);
        }
        if (value) {
          return (
            <EllipsisTooltip
              content={transformedPhoneNumber ?? EMPTY_CELL_VALUE}
              maxWidth={`${info.column.getSize()}px`}
            />
          );
        }
        return EMPTY_CELL_VALUE;
      },
    }),
    columnHelper.accessor((row) => row.deletedBy?.name, {
      id: TABLE_COLUMNS.deletedBy,
      size: columnSizes.lg,
      header: () => {
        return (
          <SortableHeaderCell fieldName="deletedBy">
            {t('deleted-contact.deleted-by', {
              defaultValue: 'Deleted by',
            })}
          </SortableHeaderCell>
        );
      },
      cell: (info) => {
        return (
          <Stack direction="row" alignItems="center" spacing={1}>
            <Avatar
              alt={info.getValue()}
              sx={{ width: '24px', height: '24px' }}
            />
            <EllipsisTooltip
              content={info.getValue() ?? EMPTY_CELL_VALUE}
              maxWidth={`${info.column.getSize()}px`}
            />
          </Stack>
        );
      },
    }),
    columnHelper.accessor((row) => row.deletedAt, {
      id: TABLE_COLUMNS.deletedOn,
      size: columnSizes.lg,
      header: () => {
        return (
          <SortableHeaderCell fieldName="deletedOn">
            {t('deleted-contact.deleted-on', {
              defaultValue: 'Deleted on',
            })}
          </SortableHeaderCell>
        );
      },
      cell: (info) => {
        return dayjs(info.getValue()).isValid()
          ? dayjs(info.getValue()).format('DD MMM YYYY h:mm A')
          : EMPTY_CELL_VALUE;
      },
    }),
    columnHelper.accessor((row) => row.scheduledHardDeleteAt, {
      id: TABLE_COLUMNS.daysRemaining,
      size: columnSizes.lg,
      header: () => {
        return (
          <SortableHeaderCell fieldName="daysRemaining">
            {t('deleted-contact.days-remaining', {
              defaultValue: 'Days remaining',
            })}
          </SortableHeaderCell>
        );
      },
      cell: (info) => {
        if (!dayjs(info.getValue()).isValid()) {
          return EMPTY_CELL_VALUE;
        }
        const leftDays = dayjs(info.getValue()).diff(dayjs(), 'days');
        return leftDays < 1
          ? t('deleted-contact.less-than-one-day', {
              defaultValue: 'less than 1 day',
            })
          : `
        ${leftDays} ${t('deleted-contact.days', {
              defaultValue: 'days',
            })}`;
      },
    }),
  ];
}

function TableActionBar({
  selectedRows,
  setRowSelection,
  totalDeletedContactIds,
  restorableContacts,
  contactLimit,
}: {
  selectedRows: string[];
  setRowSelection: (updater: Updater<RowSelectionState>) => void;
  totalDeletedContactIds: string[];
  restorableContacts: number;
  contactLimit: number;
}) {
  const { t } = useTranslation();
  const isAllRowsSelected =
    selectedRows.length === totalDeletedContactIds.length;

  const handleSelectAllRows = () => {
    setRowSelection((prev) => {
      return {
        ...prev,
        ...totalDeletedContactIds.reduce<Record<string, boolean>>(
          (prevVal, newVal) => {
            prevVal[newVal] = true;
            return prevVal;
          },
          {},
        ),
      };
    });
  };
  const handleUnselectAllRows = () => {
    setRowSelection({});
  };

  return (
    <TableRow>
      <TableCell
        sx={{
          backgroundColor: 'gray.20',
        }}
        colSpan={Object.keys(TABLE_COLUMNS).length + 1}
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="headline4" color="darkBlue.90">
            {t('deleted-contact.selected-row', {
              count: selectedRows.length,
              defaultValue:
                '{count, plural, one{# contact} other{# contacts}} selected',
            })}
          </Typography>
          <TableActionBarItem
            onClick={
              isAllRowsSelected ? handleUnselectAllRows : handleSelectAllRows
            }
            label={
              isAllRowsSelected
                ? t('deleted-contact.deselect-all-contacts', {
                    defaultValue:
                      'Deselect All {count, plural, one{# contact} other{# contacts}}',
                    count: totalDeletedContactIds.length,
                  })
                : t('deleted-contact.select-all-contacts', {
                    defaultValue: 'Select All {count} contacts',
                    count: totalDeletedContactIds.length,
                  })
            }
          />
          <RestoreBulkDeletedContacts
            selectedContacts={selectedRows}
            restorableContacts={restorableContacts}
            contactLimit={contactLimit}
            onRestore={() => {
              setRowSelection({});
            }}
          />
          <HardDeleteContacts
            selectedContacts={selectedRows}
            onHardDelete={() => {
              setRowSelection({});
            }}
            renderButton={({ setOpen }) => (
              <TableActionBarItem
                icon="trash-x"
                label={t('deleted-contact.permanently-delete', {
                  defaultValue: 'Permanently delete',
                })}
                onClick={setOpen}
              />
            )}
          />
        </Stack>
      </TableCell>
    </TableRow>
  );
}

function DeletedContactsPagination({
  page,
  pageCount,
  totalPagedContacts,
  totalContacts,
}: {
  totalPagedContacts: number | null | undefined;
  totalContacts: number | null | undefined;
  page: number;
  pageCount: number;
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const pagedContactsFrom = page * TABLE_PAGE_SIZE - TABLE_PAGE_SIZE + 1;

  return (
    <Box
      display="grid"
      gridTemplateColumns="1fr repeat(1, auto) 1fr"
      justifyItems="center"
      alignItems="center"
      justifyContent="space-evenly"
      position="sticky"
      bottom={0}
      pt="16px"
      px="20px"
      sx={{ background: 'white' }}
    >
      <Typography sx={{ textAlign: 'left', marginRight: 'auto' }}>
        {t('deleted-contact.paginated-contacts', {
          pagedContactsFrom: pagedContactsFrom ?? 0,
          pagedContactsTo: totalPagedContacts ?? 0,
          totalContacts: totalContacts ?? 0,
          defaultValue:
            '{pagedContactsFrom,number}-{pagedContactsTo,number} / {totalContacts, plural, one {# contact} other {# contacts}}',
        })}
      </Typography>
      <Pagination
        sx={{ marginLeft: 'auto' }}
        onChange={(_, value) => {
          if (value > 1) {
            searchParams.set(SEARCH_PARAMS.page, String(value));
          } else {
            searchParams.delete(SEARCH_PARAMS.page);
          }
          setSearchParams(searchParams);
        }}
        page={page}
        count={pageCount}
      />
    </Box>
  );
}

function SortableHeaderCell({
  children,
  fieldName,
}: {
  children: React.ReactNode;
  fieldName: keyof typeof SORTABLE_COLUMNS;
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamDirection = searchParams.get(SEARCH_PARAMS.direction);
  const searchParamOrderBy = searchParams.get(SEARCH_PARAMS.orderBy);
  const direction =
    searchParamDirection &&
    Object.keys(SORT_DIRECTION).includes(searchParamDirection)
      ? (searchParamDirection as keyof typeof SORT_DIRECTION)
      : 'noOrder';

  const handleSortingClick = () => {
    const currentOrderBy = SORTABLE_COLUMNS[fieldName];
    if (searchParamOrderBy !== currentOrderBy) {
      searchParams.set(SEARCH_PARAMS.direction, SORT_DIRECTION_MAP.asc);
      searchParams.set(SEARCH_PARAMS.orderBy, SORTABLE_COLUMNS[fieldName]);
      setSearchParams(searchParams);
      return;
    }

    const nextDirectionIndex = (Number(SORT_DIRECTION[direction]) + 1) % 3;
    if (nextDirectionIndex === 0) {
      searchParams.delete(SEARCH_PARAMS.direction);
      searchParams.delete(SEARCH_PARAMS.orderBy);
    } else {
      const nextDirection = Object.keys(SORT_DIRECTION)[nextDirectionIndex];
      searchParams.set(SEARCH_PARAMS.direction, nextDirection);
    }
    setSearchParams(searchParams);
  };

  return (
    <Box
      onClick={handleSortingClick}
      sx={{
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <span>{children}</span>
      <Icon
        icon={
          searchParamOrderBy === SORTABLE_COLUMNS[fieldName]
            ? SORT_DIRECTION_ICON[direction]
            : SORT_DIRECTION_ICON['noOrder']
        }
        size={16}
        sx={(theme) => {
          return {
            color: theme.palette.gray[70],
            marginLeft: 5,
          };
        }}
      />
    </Box>
  );
}

function TableActionBarItem({
  icon,
  label,
  onClick,
}: {
  icon?: IconProps['icon'];
  label?: string;
  onClick?: () => void;
}) {
  return (
    <Button
      onClick={onClick}
      size="compact-small"
      sx={{
        color: 'darkBlue.70',
        padding: 0,

        '& > .MuiButton-startIcon': {
          marginRight: '8px',
        },
      }}
      startIcon={icon && <Icon size={16} icon={icon} />}
    >
      <Typography variant="menu1">{label}</Typography>
    </Button>
  );
}
