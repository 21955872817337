import { Menu, MenuItem } from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useRestoreDeletedUserProfilesMutation } from '@/api/userProfile';
import { CustomDialog } from '@/components/AlertDialog';
import { Button } from '@/components/Button';
import Dropdown from '@/components/DropdownMenu';
import Icon from '@/components/Icon';
import { IconButton } from '@/components/IconButton';
import TableActionBarItem from '@/components/TableActionBarItem';
import { ROUTES } from '@/constants/navigation';
import { useRouteWithLocale } from '@/hooks/useRouteWithLocale/useRouteWithLocale';
import useSnackbar from '@/hooks/useSnackbar';

import { useSettingAccessRuleGuard } from '../hooks/useSettingAccessRuleGuard';
import HardDeleteContacts from './HardDeleteContact';

export function RestoreBulkDeletedContacts({
  selectedContacts,
  restorableContacts,
  contactLimit,
  onRestore,
}: {
  selectedContacts: string[];
  restorableContacts: number;
  contactLimit: number;
  onRestore: () => void;
}) {
  const [open, setOpen] = useState(false);
  const snackbar = useSnackbar();
  const { t } = useTranslation();
  const restoreDeletedUserProfiles = useRestoreDeletedUserProfilesMutation();
  const canRestore = restorableContacts >= selectedContacts.length;

  return (
    <>
      <TableActionBarItem
        icon="restore"
        label={t('deleted-contact.restore', {
          defaultValue: 'Restore',
        })}
        onClick={() => setOpen(true)}
      />

      {canRestore ? (
        <CustomDialog
          hideIcon
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          renderDialogActions={({ closeModal }) => (
            <>
              <Button
                variant="contained"
                size="lg"
                onClick={() => {
                  restoreDeletedUserProfiles.mutate(selectedContacts, {
                    onSuccess: (count) => {
                      closeModal();
                      snackbar.info(
                        t('deleted-contact.success-restore-bulk', {
                          defaultValue:
                            'You have restored {count, plural, one {# contact} other {# contacts}}',
                          count,
                        }),
                      );
                      onRestore();
                    },
                    onError: () => {
                      setOpen(false);
                      snackbar.error(
                        t('deleted-contact.restore-error', {
                          defaultValue:
                            'Unable to process the contact recovery request as the system is still handling your previous request. Please try again later.',
                        }),
                      );
                    },
                  });
                }}
                loading={restoreDeletedUserProfiles.isLoading}
              >
                {t('deleted-contact.restore', {
                  defaultValue: 'Restore',
                })}
              </Button>
            </>
          )}
          title={t('deleted-contact.dialog.title', {
            defaultValue:
              'Restore {count, plural, one {# contact} other {# contacts}}',
            count: selectedContacts.length,
          })}
          description={t('deleted-contact.restore-dialog.content', {
            defaultValue:
              'Restoring the selected contacts will return them to the Contacts page with all of their data. Conversations with the selected contacts will return to Inbox as well.',
          })}
        />
      ) : (
        <ExceedLimitAlertDialog
          open={open}
          setOpen={setOpen}
          contactLimit={contactLimit}
        />
      )}
    </>
  );
}

export function DeletedContactRowActions({
  contact,
  restorableContacts,
  contactLimit,
}: {
  contact: {
    name: string;
    id: string;
  };
  restorableContacts: number;
  contactLimit: number;
}) {
  const [open, setOpen] = useState(false);
  const snackbar = useSnackbar();
  const { t } = useTranslation();
  const restoreDeletedUserProfiles = useRestoreDeletedUserProfilesMutation();
  const canRestore = restorableContacts >= 1;

  const handleRestoreDeletedUserProfile = () =>
    restoreDeletedUserProfiles.mutate([contact.id], {
      onSuccess: () => {
        snackbar.info(
          t('deleted-contact.success-restore-single', {
            defaultValue: 'You have restored {name}',
            name: contact.name,
          }),
        );
      },
      onError: () => {
        setOpen(false);
        snackbar.error(
          t('deleted-contact.restore-error', {
            defaultValue:
              'Unable to process the contact recovery request as the system is still handling your previous request. Please try again later.',
          }),
        );
      },
    });

  return (
    <Dropdown>
      {({ handleOpen, ...menuProps }) => (
        <>
          <IconButton
            size="small"
            aria-label={t('flow-builder.toggle-flow-action-menu')}
            onClick={handleOpen}
          >
            <Icon sx={{ color: 'gray.70' }} icon="dots-vertical" size={16} />
          </IconButton>
          <Menu {...menuProps}>
            <MenuItem
              onClick={() => {
                canRestore ? handleRestoreDeletedUserProfile() : setOpen(true);
              }}
            >
              {t('deleted-contact.restore', {
                defaultValue: 'Restore',
              })}
            </MenuItem>
            <HardDeleteContacts
              renderButton={({ setOpen }) => (
                <MenuItem onClick={setOpen}>
                  {t('deleted-contact.permanently-delete', {
                    defaultValue: 'Permanently delete',
                  })}
                </MenuItem>
              )}
              selectedContacts={[contact.id]}
              onSuccessSnackbarMessage={t(
                'deleted-contact.success-hard-delete-with-contact-name',
                {
                  defaultValue: 'You have permanently deleted {contactName}',
                  contactName: contact.name,
                },
              )}
            />
          </Menu>
          <ExceedLimitAlertDialog
            open={open}
            setOpen={setOpen}
            contactLimit={contactLimit}
          />
        </>
      )}
    </Dropdown>
  );
}

function ExceedLimitAlertDialog({
  contactLimit,
  open,
  setOpen,
}: {
  contactLimit: number;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const { t } = useTranslation();
  const routeTo = useRouteWithLocale();
  const { isSubscriptionsAllowToView } = useSettingAccessRuleGuard();

  return (
    <>
      <CustomDialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        renderDialogActions={({ closeModal }) => (
          <>
            {isSubscriptionsAllowToView() ? (
              <Button
                variant="contained"
                component={Link}
                size="lg"
                to={routeTo(ROUTES.settingsSubscriptionsManagePlan)}
              >
                {t('deleted-contact.go-to-settings', {
                  defaultValue: 'Go to Settings',
                })}
              </Button>
            ) : (
              <Button variant="contained" size="lg" onClick={closeModal}>
                {t('deleted-contact.close', {
                  defaultValue: 'Close',
                })}
              </Button>
            )}
          </>
        )}
        title={t('deleted-contact.exceed-limit-dialog.title', {
          defaultValue: 'Contact limit exceeded',
        })}
        description={t('deleted-contact.dialog.content', {
          defaultValue:
            'You have exceeded the contact limit of {contactLimit, number}. Please contact your workspace admin for support.',
          contactLimit,
        })}
      />
    </>
  );
}
