import { useCompany } from '@/api/company';
import { RoleType } from '@/api/types';
import { useMyProfile } from '@/hooks/useMyProfile';
import { fromApiBillRecordsToActiveBillRecord } from '@/utils/billing';
import { isFreeOrFreemiumPlan } from '@/utils/subscription-plan-checker';

export const useAISettingsRoleBasedAccessControl = ({
  suspense,
}: { suspense?: boolean } = {}) => {
  const userProfile = useMyProfile({ suspense });
  const company = useCompany({
    select: ({ billRecords }) => {
      const activeBillRecord =
        fromApiBillRecordsToActiveBillRecord(billRecords);
      return {
        isFreeTier:
          !activeBillRecord?.subscriptionPlan ||
          isFreeOrFreemiumPlan(activeBillRecord?.subscriptionPlan),
      };
    },
  });

  return {
    canViewAISettings: userProfile.data?.roleType === RoleType.ADMIN,
    isFreeTier: company.data?.isFreeTier ?? true,
  };
};
