import { interfaces } from 'inversify';
import {
  filter,
  merge,
  Observable,
  switchMap,
  take,
  takeUntil,
  throttleTime,
} from 'rxjs';

import { UserService } from '@/services/user.service';

import { SimpleObjectDataSource } from '../data-sources/simple-object-data-source';
import { ConversationMatcherService } from './conversation-matcher.service';
import { ConversationSummaryService } from './conversation-summary.service';
import { ConversationService } from './conversation.service';
import { ConversationSummary } from './models/conversation-summary';
import { GetConversationsFilter } from './models/get-conversations-filter';

export class ConversationSummaryDataSource extends SimpleObjectDataSource<ConversationSummary> {
  private readonly conversationService: ConversationService;
  private readonly conversationSummaryService: ConversationSummaryService;
  private readonly conversationMatcherService: ConversationMatcherService;
  private readonly userService: UserService;

  private hasSetup = false;

  constructor(container: interfaces.Container) {
    super();

    this.conversationService =
      container.get<ConversationService>(ConversationService);
    this.conversationSummaryService = container.get<ConversationSummaryService>(
      ConversationSummaryService,
    );
    this.conversationMatcherService = container.get<ConversationMatcherService>(
      ConversationMatcherService,
    );
    this.userService = container.get<UserService>(UserService);
  }

  public setupAndGet$(
    getConversationsFilter: GetConversationsFilter,
  ): Observable<ConversationSummary> {
    if (this.hasSetup) {
      return this.getCachedItem$();
    }

    this.hasSetup = true;

    this.setup(getConversationsFilter);

    return this.getCachedItem$();
  }

  private setup(getConversationsFilter: GetConversationsFilter) {
    this.fetch(getConversationsFilter);

    merge(
      this.conversationService.getOngoingUpdatedConversation$(
        getConversationsFilter,
      ),
      this.userService.getMyStaff$().pipe(
        switchMap((myStaff) => {
          return this.conversationService
            .getCachedConversationUpdate$()
            .pipe(
              filter(([, cwu]) =>
                this.conversationMatcherService.matchConversationWrapperUpdate(
                  getConversationsFilter,
                  cwu,
                  myStaff.staffId,
                ),
              ),
            );
        }),
      ),
    )
      .pipe(
        takeUntil(this.getDisconnect$()),
        throttleTime(10000, undefined, { leading: false, trailing: true }),
      )
      .subscribe(() => {
        this.fetch(getConversationsFilter);
      });
  }

  private fetch(getConversationsFilter: GetConversationsFilter): void {
    let observable$: Observable<ConversationSummary> | undefined;
    if (getConversationsFilter.assignedTeamId) {
      observable$ = this.conversationSummaryService.getTeamConversationSummary$(
        getConversationsFilter,
      );
    }
    // assignedTeamId should be checked first because assignedStaffId can also be a subset of assignedTeamId
    else if (getConversationsFilter.assignedStaffId) {
      observable$ =
        this.conversationSummaryService.getStaffConversationSummary$(
          getConversationsFilter,
        );
    } else if (getConversationsFilter.isCollaborated) {
      observable$ =
        this.conversationSummaryService.getCollaboratedConversationSummary$(
          getConversationsFilter,
        );
    } else if (getConversationsFilter.isMentioned) {
      observable$ =
        this.conversationSummaryService.getMentionedConversationSummary$(
          getConversationsFilter,
        );
    } else {
      observable$ = this.conversationSummaryService.getAllConversationSummary$(
        getConversationsFilter,
      );
    }

    observable$
      .pipe(takeUntil(this.getDisconnect$()), take(1))
      .subscribe((conversationSummary) => {
        this.onNextCachedItem(conversationSummary);
      });
  }
}
