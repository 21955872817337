export const TABS = {
  library: 'library',
  settings: 'settings',
} as const;

export type AiSettingsTab = typeof TABS[keyof typeof TABS];

export const STATUS_COLOR: Record<string, 'mustard' | 'forest' | 'red'> = {
  pending: 'mustard',
  processing: 'mustard',
  completed: 'forest',
  failed: 'red',
};

export const PAGE_STORAGE_LIMIT = 1000;
