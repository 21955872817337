import { Typography, Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { HttpStatusCodeDict } from '@/api/types';
import { Button } from '@/components/Button';
import Icon from '@/components/Icon';
import PageLayoutSlot from '@/components/PageLayoutSlot';
import PageTitle from '@/components/PageTitle';
import { ScrollArea } from '@/components/ScrollArea';
import { useSettingsLayout } from '@/pages/Settings/shared/SettingsLayout';

import { useSettingAccessRuleGuard } from '../hooks/useSettingAccessRuleGuard';
import SettingsAccessDeniedError from '../shared/SettingsAccessDeniedError';
import MainTable from './MainTable';

export default function AuditLog() {
  const { t } = useTranslation();
  const { pageTitleEl } = useSettingsLayout();
  const accessRuleGuard = useSettingAccessRuleGuard();
  if (!accessRuleGuard.isAuditLogAllowToView()) {
    throw new SettingsAccessDeniedError({
      status: HttpStatusCodeDict.forbidden,
    });
  }

  return (
    <ScrollArea>
      <PageLayoutSlot anchorEl={pageTitleEl}>
        <PageTitle
          title={t('settings.menu.company-settings.audit-log', {
            defaultValue: 'Audit log',
          })}
          subtitleComponent={
            <Typography variant="subtitle">
              {t('settings.menu.company-settings.title', {
                defaultValue: 'COMPANY SETTINGS',
              })}
            </Typography>
          }
        />
      </PageLayoutSlot>
      <Stack spacing={3}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Stack spacing={1}>
            <Typography variant="headline3">
              {t('settings.audit-log.title', {
                defaultValue: 'Audit log',
              })}
            </Typography>
            <Typography variant="body1">
              {t('settings.audit-log.description', {
                defaultValue:
                  'Track and review all activities and updates in your company account',
              })}
            </Typography>
          </Stack>
          <Box>
            <Button
              variant="text"
              startIcon={<Icon icon="download" size={16} />}
              disabled // TODO: Enable this button after implementing the export feature
            >
              {t('settings.audit-log.export', {
                defaultValue: 'Export',
              })}
            </Button>
          </Box>
        </Box>
        <Box>filter placeholder</Box>
        <MainTable />
      </Stack>
    </ScrollArea>
  );
}
