import { Suspense, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useDialogStore } from '@/hooks/useDialog';
import { lazyWithRetries } from '@/utils/lazy-loading';

export const GLOBAL_DIALOGS = {
  'create-ticket': lazyWithRetries(() => import('./CreateTicket')),
  'view-ticket': lazyWithRetries(() => import('./ViewTicket')),
} as const;

export type GlobalDialogs = keyof typeof GLOBAL_DIALOGS;

export const GlobalDialogs = () => {
  const { id, open, onOpen, onClose } = useDialogStore();
  const [searchParams] = useSearchParams();

  const urlDialogId = searchParams.get('dialog');

  useEffect(() => {
    if (!open && urlDialogId && urlDialogId in GLOBAL_DIALOGS) {
      onOpen(urlDialogId as GlobalDialogs);
    }
    if (open && !urlDialogId) {
      onClose();
    }
  }, [onClose, onOpen, open, urlDialogId]);

  const Dialog = id && GLOBAL_DIALOGS[id];

  return <Suspense fallback={null}>{!!Dialog && <Dialog />}</Suspense>;
};
