import {
  SchemaRelationshipType as CustomObjectRelationship,
  SchemaRelationshipType,
} from '@/api/customObject';

export const TEMPLATE_OPTIONS = [
  'companies',
  'events',
  'bookings',
  'payments',
  'contacts',
  'subscriptions',
  'orders',
  'courses',
  'quotes',
] as const;

export type TemplateType = typeof TEMPLATE_OPTIONS[number];
export type ObjectType = TemplateType | 'scratch';

export type CustomObject = {
  name: string;
  id: string;
  displayedId: string;
  relationship: CustomObjectRelationship;
  isEnabled: boolean;
  updatedAt: string;
  primaryProperty: CustomObjectPrimaryProperty;
  properties: CustomObjectProperty[];
};

export type CustomObjectProperty = {
  id: string;
  name: string;
  displayedId: string;
  type: CustomObjectPropertyType;
  isRequired: boolean;
  isSearchable: boolean;
  isPinned: boolean;
  isVisible: boolean;
  createdBy?: {
    staffId: string;
    teamIds: string[];
  };
  createdAt: string;
  options?: CustomObjectPropertyOption[];
};

export type CustomObjectPropertyOption = {
  id: string;
  value: string | number | boolean;
};

export type CustomObjectPrimaryProperty = Omit<
  CustomObjectProperty,
  'isRequired'
> & {
  isIdAutoGenerated: boolean;
  displayedIdPrefixConfig?: {
    prefix: string;
    digitLength: number;
  };
};

export const PROPERTY_TYPE = [
  'number',
  'decimalNumber',
  'multiSelection',
  'singleSelection',
  'text',
  'date',
  'dateTime',
  'boolean',
] as const;

export type CustomObjectPropertyType = typeof PROPERTY_TYPE[number];

export const DROPDOWN_TYPE = ['singleSelection', 'multiSelection'];

export type CreateCustomObjectForm = {
  name: string;
  displayedId: string;
  relationship: SchemaRelationshipType;
  primaryProperty: {
    name: string;
    displayedId: string;
    type: 'text';
    isRequired: boolean;
    isIdAutoGenerated: boolean;
    displayedIdPrefixConfig: {
      prefix: string;
    };
  };
  properties?: {
    name: string;
    displayedId: string;
    type: CustomObjectPropertyType;
    isRequired: boolean;
    options?: {
      value: string;
    }[];
  }[];
};
